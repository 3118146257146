import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterListIcon from '@material-ui/icons/FilterList';
import IconButton from '@material-ui/core/IconButton';
import { Grid, InputLabel, NativeSelect } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import CircularProgress from '@material-ui/core/CircularProgress';
import fire from '../config/fire'

const columns = [
    { id: 'name', label: 'Name', minWidth: 100, align: 'left' },
    {
        id: 'experience',
        label: 'Experience',
        minWidth: 50,
        align: 'center'
    },
    {
        id: 'score',
        label: 'Score',
        minWidth: 50,
        align: 'center',
    }
];

export default class Leaderboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rows: [],
            info: [],
            page: "0",
            rowsPerPage: "10",
            quiz: "All"
        }
    }

    handleInput = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })

        this.mount(event.target.value)
    };

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        });
    };

    handleChangeRowsPerPage = (event) => {

        this.setState({
            rowsPerPage: +event.target.value
        });

        this.setState({
            page: "0"
        });
    };


    mount(quiz) {

        if (quiz === 'All') {
            let list = []
            let holder = {}
            let obj2 = []

            fire.database().ref('Java_stack').on('value', snapshot => {

                snapshot.forEach(snap => {
                    if (snap.val().score != "")
                        list.push(snap.val());
                })
            })

            fire.database().ref('UI_Developer').on('value', snapshot => {

                snapshot.forEach(snap => {
                    if (snap.val().score != "")
                        list.push(snap.val());
                })
            })

            fire.database().ref('Devops').on('value', snapshot => {

                snapshot.forEach(snap => {
                    if (snap.val().score != "")
                        list.push(snap.val());
                })
            })


            fire.database().ref('Competition').on('value', snapshot => {

                snapshot.forEach(snap => {
                    if (snap.val().score != "")
                        list.push(snap.val());
                })
            })


            fire.database().ref('Boolean_basics').on('value', snapshot => {

                snapshot.forEach(snap => {
                    if (snap.val().score != "")
                        list.push(snap.val());
                })

                list.forEach(function (d) {
                    if (holder.hasOwnProperty(d.uid + " " + d.name)) {
                        holder[d.uid + " " + d.name] = holder[d.uid + " " + d.name] + parseFloat(d.score);
                    } else {
                        holder[d.uid + " " + d.name] = parseFloat(d.score);
                    }
                });

                for (let p in holder) {
                    obj2.push({ uid: p.substr(0, p.indexOf(' ')), score: holder[p], name: p.substr(p.indexOf(' ') + 1, p.indexOf(' ')) });
                }

                let ans = obj2.sort(function (a, b) {

                    return parseFloat(b.score) - parseFloat(a.score);
                });

                this.setState({ rows: ans })
            })
        }
        else {
            fire.database().ref(quiz).once('value', snapshot => {
                let list = []

                snapshot.forEach(snap => {
                    if (snap.val().score != "")
                        list.push(snap.val());
                })

                let ans = list.sort(function (a, b) {

                    return parseFloat(b.score) - parseFloat(a.score);
                });

                this.setState({ rows: ans })
            })
        }

        fire.database().ref("Details").once('value', snapshot => {
            let i = []

            snapshot.forEach(snap => {
                i.push(snap.val());
            })

            this.setState({ info: i })
        })
    }

    componentDidMount() {
        this.mount(this.state.quiz)
    }

    render() {
        return (
            <Grid container id="leaderboard" style={{ backgroundColor: '#f3f7f7', paddingBottom: '50px' }}>
                <Grid container>
                    <h1 style={{ paddingLeft: '10px' }}>Leaderboard</h1>
                </Grid>
                <div className="leaders">
                    <Grid container className="leader-quiz">
                        <Grid item className="cat-chooser">
                            <InputLabel className="filter-label" htmlFor="CONTESTS">CONTESTS  <span style={{ paddingLeft: '30px' }}> | </span></InputLabel>
                            <NativeSelect id="CONTESTS" name="quiz" className="cat-chooser-select"
                                value={this.state.quiz} onChange={this.handleInput} >
                                <option value="All" selected>All</option>
                                <option value="Boolean_basics">Boolean Basics</option>
                                <option value="Java_stack">Java Stack - I</option>
                                <option value="UI_Developer">UI Developer</option>
                                <option value="Devops">Devops</option>
                                <option value="Competition">Competition</option>
                            </NativeSelect>
                            <ExpandMoreIcon style={{ marginTop: '5px' }} />
                        </Grid>
                        <Grid item className="cat-chooser" style={{ display: 'none', marginLeft: '20px', float: 'right' }}>
                            <label className="filter-label">Filter</label>
                            <FilterListIcon style={{ marginLeft: '15px', marginTop: '4px', color: 'rgba(0, 0, 0, 0.54)' }} />
                        </Grid>
                    </Grid>
                    {this.state.rows.length > 0 ?
                        <Paper className="root" >
                            <TableContainer>
                                <Table aria-label="leaderboard">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth, color: '#738f93', fontWeight: '400', textTransform: 'uppercase', fontSize: '18px', letterSpacing: '2px' }}
                                                >
                                                    {column.label === 'Score' ? this.state.quiz === 'All' ? column.label + "/40" : column.label + "/10" : column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            this.state.rows.slice(parseInt(this.state.page) * parseInt(this.state.rowsPerPage), parseInt(this.state.page) * parseInt(this.state.rowsPerPage) + parseInt(this.state.rowsPerPage)).map((i) => {
                                                return (
                                                    this.state.info.map(data => {
                                                        return (
                                                            data.uid === i.uid ?
                                                                <tr key={i.uid}>
                                                                    <td className="td-name">{i.name.substr(0, i.name.indexOf(' '))} <span>
                                                                        <IconButton href={data.linkedin} className="linkedin">
                                                                            <LinkedInIcon />
                                                                        </IconButton></span></td>
                                                                    <td>{data.experience}</td>
                                                                    <td>{i.score}</td>
                                                                </tr> : <tr></tr>
                                                        )
                                                    })
                                                );
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 20]}
                                component="div"
                                count={20}
                                rowsPerPage={parseInt(this.state.rowsPerPage)}
                                page={parseInt(this.state.page)}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            />
                        </Paper > : <div align="center" style={{ marginTop: '60px' }}>
                            <CircularProgress thickness="5" />
                        </div>
                    }
                </div>
            </Grid>
        );
    }
}