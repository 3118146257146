import React from 'react'
import { Grid } from '@material-ui/core';
import Card1 from './Cards/Card1'
import Card2 from './Cards/Card2'
import Card3 from './Cards/Card3'
import Card4 from './Cards/Card4'
import Sidebar from './Sidebar'
import Header from './Header'

function Home(props) {

    return (
        <div>
            <Header />
            <Grid className="cards" container>
                {
                    props.uid !== null ?
                        <Grid item className="card" xs={12} md={7}>
                            <h1 style={{ color: '#b32800', paddingLeft: '10px', marginTop: '-5px' }}>Recruiter Assessment</h1>
                            <div><Card1 uid={props.uid} /></div>
                            <div><Card2 uid={props.uid} /></div>
                            <div><Card3 uid={props.uid} /></div>
                            <div><Card4 uid={props.uid} /></div>
                            <div><button onClick={()=> {window.location.href = '/new-quiz'}}>navigate</button></div>
                        </Grid> :
                        <Grid item className="card" xs={12} md={7}>
                            <h1 style={{ color: '#b32800', paddingLeft: '10px', marginTop: '-5px' }}>Recruiter Assessment</h1>
                            <div><Card1 uid={null} /></div>
                            <div><Card2 uid={null} /></div>
                            <div><Card3 uid={null} /></div>
                            <div><Card4 uid={null} /></div>
                        </Grid>
                }
                <Grid item xs={12} md={5} className="side-bar">
                    <Sidebar />
                </Grid>
            </Grid>
        </div>
    )
};
export default Home