import React from "react";

const QuizDetail = () => {
  return (
    <div className="quiz_detail_container">
      <div>
        Looking to hire top tech recruiters or improve your Talent Acquisition
        (TA) skills?
      </div>

      <button className="connect_button" onClick={()=> window.open("https://wa.me/+917416266999", "_blank")}>
        Talk to us!
      </button>
    </div>
  );
};

export default QuizDetail;
