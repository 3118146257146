import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import fire from "../config/fire";
import CanvasJSReact from '../assets/canvasjs.react'
import CircularProgress from '@material-ui/core/CircularProgress';
import { InputLabel, NativeSelect } from '@material-ui/core';
import moment from 'moment'

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default class Graph extends Component {

    constructor(props) {
        super(props)

        this.state = {
            quiz: 'All Quizzes',
            a1: '',
            a2: '',
            a3: '',
            a4: '',
            a5: '',
            detail: [],
            date: 'All Time'
        }
    }

    mount(quiz) {

        if (quiz == 'All Quizzes') {
            let list = []
            fire.database().ref('Java_stack').once('value', snapshot => {

                snapshot.forEach(snap => {
                    if (snap.val().score != "")
                        list.push(snap.val());
                })
            })

            fire.database().ref('UI_Developer').once('value', snapshot => {

                snapshot.forEach(snap => {
                    if (snap.val().score != "")
                        list.push(snap.val());
                })
            })

            fire.database().ref('Devops').once('value', snapshot => {

                snapshot.forEach(snap => {
                    if (snap.val().score != "")
                        list.push(snap.val());
                })
            })

            fire.database().ref('Boolean_basics').once('value', snapshot => {

                snapshot.forEach(snap => {
                    if (snap.val().score != "")
                        list.push(snap.val());
                })

                this.setState({
                    a1: list.filter(o => o.score <= 2).length,
                    a2: list.filter(o => o.score > 2 && o.score <= 4).length,
                    a3: list.filter(o => o.score > 4 && o.score <= 6).length,
                    a4: list.filter(o => o.score > 6 && o.score <= 8).length,
                    a5: list.filter(o => o.score > 8).length
                })

                this.setState({ detail: list })
            })
        }
        else {
            fire.database().ref(quiz).once('value', snapshot => {
                let list = []

                snapshot.forEach(snap => {
                    if (snap.val().score != "")
                        list.push(snap.val());
                })

                this.setState({
                    a1: list.filter(o => o.score <= 2).length,
                    a2: list.filter(o => o.score > 2 && o.score <= 4).length,
                    a3: list.filter(o => o.score > 4 && o.score <= 6).length,
                    a4: list.filter(o => o.score > 6 && o.score <= 8).length,
                    a5: list.filter(o => o.score > 8).length
                })

                this.setState({ detail: list })
            })
        }
    }

    componentDidMount() {
        this.mount(this.state.quiz)
    }

    handleDate = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });

        let list = []
        if (e.target.value === "All Time")
            this.componentDidMount()
        else if (e.target.value === "Today") {
            var d = new Date();
            var k = new Date(d.getFullYear(), d.getMonth(), d.getDate()).toLocaleString("en-IN");

            this.state.detail.filter(data => {
                if (moment(data.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x") >= moment(k, "DD/MM/YYYY hh:mm:ss am/pm").format("x")) {
                    list.push(data)
                }
            })
        }
        else if (e.target.value === "Yesterday") {
            var d = new Date();
            var x = new Date(d.getFullYear(), d.getMonth(), d.getDate()).toLocaleString("en-IN");
            var y = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 1).toLocaleString("en-IN");
            var p = moment(x, "DD/MM/YYYY hh:mm:ss am/pm").format("x")
            var k = moment(y, "DD/MM/YYYY hh:mm:ss am/pm").format("x")

            this.state.detail.filter(data => {
                if (moment(data.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x") >= k && data.date < p) {
                    list.push(data)
                }
            })
        }
        else if (e.target.value === "Last Week") {
            var d = new Date();
            var x = new Date(d.getFullYear(), d.getMonth(), d.getDate()).toLocaleString("en-IN");
            var y = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7).toLocaleString("en-IN");
            var p = moment(x, "DD/MM/YYYY hh:mm:ss am/pm").format("x")
            var k = moment(y, "DD/MM/YYYY hh:mm:ss am/pm").format("x")

            this.state.detail.filter(data => {
                if (moment(data.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x") >= k && data.date < p) {
                    list.push(data)
                }
            })
        }
        else if (e.target.value === "Last Month") {
            var d = new Date();
            var x = new Date(d.getFullYear(), d.getMonth(), d.getDate()).toLocaleString("en-IN");
            var y = new Date(d.getFullYear(), d.getMonth() - 1, d.getDate()).toLocaleString("en-IN");
            var p = moment(x, "DD/MM/YYYY hh:mm:ss am/pm").format("x")
            var k = moment(y, "DD/MM/YYYY hh:mm:ss am/pm").format("x")

            this.state.detail.filter(data => {
                if (moment(data.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x") >= k && data.date < p) {
                    list.push(data)
                }
            })
        }

        this.setState({
            a1: list.filter(o => o.score <= 2).length,
            a2: list.filter(o => o.score > 2 && o.score <= 4).length,
            a3: list.filter(o => o.score > 4 && o.score <= 6).length,
            a4: list.filter(o => o.score > 6 && o.score <= 8).length,
            a5: list.filter(o => o.score > 8).length
        })
    }

    handleQuiz = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });

        this.setState({
            date: "All Time"
        })

        this.mount(event.target.value)
    }

    options = () => {

        let data = {
            animationEnabled: true,
            exportEnabled: true,
            theme: "light1",
            title: {
                text: this.state.quiz + " - " + this.state.date
            },
            toolTip: {
                enabled: false
            },
            data: [{
                type: "pie",
                indexLabel: "{label} = {y} ",
                startAngle: 0,
                showInLegend: "true",
                legendText: "{label}",
                indexLabelFontSize: 18,
                dataPoints: [
                    { y: this.state.a1, label: "Score 0-2" },
                    { y: this.state.a2, label: "Score 2.1-4" },
                    { y: this.state.a3, label: "Score 4.1-6" },
                    { y: this.state.a4, label: "Score 6.1-8" },
                    { y: this.state.a5, label: "Score 8.1-10" }
                ]
            }]
        }

        return data
    }

    render() {
        if (this.state.detail.length > 0)
            return (
                <div>
                    <Grid container><Grid item xs={12}><p></p></Grid></Grid>
                    <Grid container className="top">
                        <Grid item xs={6} style={{ padding: '20px' }}>
                            <InputLabel htmlFor="quiz">Quiz</InputLabel>
                            <NativeSelect id="quiz" name="quiz"
                                value={this.state.quiz} onChange={this.handleQuiz}>
                                <option value="All Quizzes">All Quizzes</option>
                                <option value="Boolean_basics">Boolean Basics</option>
                                <option value="Java_stack">Java Stack - I</option>
                                <option value="UI_Developer">UI Developer</option>
                                <option value="Devops">Devops</option>
                            </NativeSelect>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2} style={{ padding: '20px' }}>
                            <p><b>Total Entries: {this.state.a1 + this.state.a2 + this.state.a3 + this.state.a4 + this.state.a5}</b></p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} style={{ padding: '20px' }}>
                            <InputLabel htmlFor="date">Date Filter</InputLabel>
                            <NativeSelect id="date" name="date"
                                value={this.state.date} onChange={this.handleDate}>
                                <option value="All Time">All Time</option>
                                <option value="Today">Today</option>
                                <option value="Yesterday">Yesterday</option>
                                <option value="Last Week">Last Week</option>
                                <option value="Last Month">Last Month</option>
                            </NativeSelect>
                        </Grid>
                    </Grid>
                    <Grid container><Grid item xs={12}><p></p></Grid></Grid>
                    <Grid container><Grid item xs={12}><p></p></Grid></Grid>
                    <CanvasJSChart options={this.options()} />
                </div>
            )
        else
            return (
                <div align="center" style={{ marginTop: '60px' }}>
                    <CircularProgress thickness="5" />
                </div>
            )
    }
}