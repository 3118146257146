import React, { Component } from "react";
import Drawer from "@material-ui/core/Drawer";
import { Button, Grid, InputLabel, NativeSelect } from "@material-ui/core";
import fire from "../config/fire";
import { TextField } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Swal from "sweetalert2";

class Drawing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      top: false,
      performance: "Very Good",
      detail: [],
      ques: [],
      category: [],
      scores: this.props.scores,
      dbperformance: this.props.dbperformance,
      updatePerformance: false,
    };
    // console.log(this.state)
  }

  componentDidMount() {
    fire
      .database()
      .ref(this.props.quiz + "/" + this.props.uid)
      .once("value", (snapshot) => {
        let list = [];

        snapshot.forEach((snap) => {
          list.push(snap.val());
        });

        this.setState({ detail: list });
      });

    fire
      .database()
      .ref("Questions/" + this.props.quiz)
      .once("value", (snapshot) => {
        let l = [];
        let qn = [];
        let cat = [];

        snapshot.forEach((snap) => {
          l.push(snap.val());
        });

        for (var i = l.length / 2; i < l.length; i++) qn.push(l[i]);

        for (var i = 0; i < l.length / 2; i++) cat.push(l[i]);

        this.setState({ ques: qn, category: cat });
      });
  }

  toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    this.setState({ [anchor]: open });
  };

  handleScore = () => {
    let s = [];

    for (var i = 0; i < this.props.scores.length; i++) {
      var id = "score" + i;
      s.push(document.getElementById(id).value);
    }

    let sum = 0;
    for (var i = 0; i < s.length; i++) sum = sum + parseFloat(s[i]);

    sum = sum.toFixed(2);
    sum = sum.toString();
    fire.database().ref(this.props.quiz).child(this.props.uid).update({
      score: sum,
      scores: s,
    });

    this.setState({ scores: s });
    this.componentDidMount();
  };

  handleEdit = () => {
    let n = [];

    for (var i = 0; i < this.props.scores.length; i++) n.push("");

    fire.database().ref(this.props.quiz).child(this.props.uid).update({
      score: "",
      scores: n,
    });

    this.setState({ scores: n });
    this.componentDidMount();
  };

  handlePerformance = (event) => {
    this.setState({ performance: event.target.value });
  };

  handleSetPerformance = () => {
    // console.log(this.state.performance)
    fire.database().ref(this.props.quiz).child(this.props.uid).update({
      performance: this.state.performance,
    });
    // update the performance
    this.setState({ dbperformance: this.state.performance });
    this.setState({ updatePerformance: false });
  };

  list = (anchor) => (
    <div className="drawer" role="presentation">
      <Grid container>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
            }}
          >
            <b style={{ fontSize: "28px" }}>{this.props.quiz}</b>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={this.toggleDrawer("top", false)}
            >
              Close
            </Button>
          </div>
          <div>
            <ol>
              {this.state.ques.map((data, index) => {
                return (
                  <div>
                    <li>
                      {data} (<b>Category: {this.state.category[index]}</b>)
                    </li>
                    {Array.isArray(this.state.detail[index]) ? (
                      <div>
                        {this.state.detail[index].map((data) => {
                          if (data.isChecked === true)
                            return (
                              <p>
                                <b>Ans.</b> {data.value}
                              </p>
                            );
                        })}
                        <div style={{ padding: "5px", marginBottom: "10px" }}>
                          {this.state.scores[index] === "" ? (
                            <Grid container>
                              <Grid item>
                                <TextField
                                  type="number"
                                  id={"score" + index}
                                  placeholder="Score"
                                  variant="outlined"
                                  size="small"
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <span>Score: {this.state.scores[index]}</span>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <span>
                          <b>Ans.</b>{" "}
                        </span>
                        {this.state.detail[index]}
                        <div style={{ padding: "5px", marginBottom: "10px" }}>
                          {this.state.scores[index] === "" ? (
                            <Grid container>
                              <Grid item>
                                <TextField
                                  type="number"
                                  id={"score" + index}
                                  placeholder="Score"
                                  variant="outlined"
                                  size="small"
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            <span>Score: {this.state.scores[index]}</span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
              <br />

              {(this.props.quiz === "Competition" ||
                this.props.quiz === "Sourcing_basic" ||
                this.props.quiz === "Tech_fundamentals" ||
                this.props.quiz === "Xray_Sourcing_Basic" ||
                this.props.quiz === "Xray_Sourcing_Advance" ||
                this.props.quiz === "Monk_Sourcing_India") &&
                (this.state.dbperformance === "" ||
                this.state.updatePerformance ? (
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      padding: "20px 0",
                      gap: "1rem",
                      alignItems: "center",
                      justifyContent: "start",
                    }}
                  >
                    <NativeSelect
                      id="quiz"
                      name="quiz"
                      value={this.state.performance}
                      onChange={this.handlePerformance}
                    >
                      <option value="Very Good">Very Good</option>
                      <option value="Good">Good</option>
                      <option value="Can do Better">Can do Better</option>
                      <option value="Poor">Poor</option>
                      <option value="Very Poor">Very Poor</option>
                    </NativeSelect>
                    <Button
                      style={{ width: "180px" }}
                      onClick={this.handleSetPerformance}
                      color="primary"
                      variant="outlined"
                    >
                      Set Performance
                    </Button>
                  </Grid>
                ) : (
                  <p>
                    Performance : {this.state.dbperformance}{" "}
                    <EditIcon
                      style={{ color: "#1677f2", cursor: "pointer" }}
                      onClick={() => this.setState({ updatePerformance: true })}
                    />
                  </p>
                ))}
              {this.state.scores[0] === "" ? (
                <Button
                  onClick={this.handleScore.bind(this)}
                  color="primary"
                  variant="outlined"
                >
                  Add Scores
                </Button>
              ) : (
                <p>
                  Total: {this.state.detail[this.state.detail.length - 3]}{" "}
                  <IconButton
                    aria-label="delete"
                    size="small"
                    color="primary"
                    onClick={this.handleEdit.bind(this)}
                  >
                    <EditIcon />
                  </IconButton>
                </p>
              )}
            </ol>
          </div>
        </Grid>
      </Grid>
    </div>
  );

  render() {
    return (
      <div>
        <Button
          variant="outlined"
          color="primary"
          onClick={this.toggleDrawer("top", true)}
        >
          Check
        </Button>
        <Drawer
          anchor="top"
          open={this.state.top}
          onClose={this.toggleDrawer("top", false)}
        >
          {this.list("top")}
        </Drawer>
      </div>
    );
  }
}

export default Drawing;
