import React from "react";

const QuizHeader = () => {
  return (
    <div className="headerContainer">
      <div className="header_content">
        <div className="header_title">
          Ready to Elevate Your Tech Recruiting Game?
        </div>
        <div className="header_subtitle">
          Assess your tech recruiting skills now on the world’s only assessment
          platform for tech recruiters
        </div>
      </div>

      <div>
        <img
          className="header_image"
          src="./images/header.svg"
          alt="recruiting_notes"
        />
      </div>
    </div>
  );
};

export default QuizHeader;
