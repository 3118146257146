import React, { useEffect, useState } from "react";
import fire from "../../config/fire";
import Swal from "sweetalert2";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Contact from "../Contact";
import { Checkbox } from "@material-ui/core";

function getSteps() {
  return [
    "Question 1",
    "Question 2",
    "Question 3",
    "Question 4",
    "Question 5",
    "Question 6",
    "Question 7",
    "Question 8",
    "Question 9",
    "Question 10",
  ];
}

function XRaySourcing_Basic_Ques(props) {
  const [enableBeforeUnload, setEnableBeforeUnload] = useState(true);

  const [state, setState] = React.useState({
    name: props.name,
    email: props.email,
    uid: props.uid,
    answer1: "",
    answer2: [
      { id: 1, value: "inurl:", isChecked: false },
      { id: 2, value: "intext:", isChecked: false },
      { id: 3, value: "intitle:", isChecked: false },
      { id: 4, value: "Incomapny:", isChecked: false },
    ],
    answer3: [
      { id: 1, value: "Profiles from India", isChecked: false },
      { id: 2, value: "Private Profiles", isChecked: false },
      { id: 3, value: "Public Profiles", isChecked: false },
      { id: 4, value: "All the Above", isChecked: false },
    ],
    answer4: "",
    answer5: "",
    answer6: [
      { id: 1, value: "Search across web", isChecked: false },
      { id: 2, value: "Search a targeted site only", isChecked: false },
      { id: 3, value: "Both 1 & 2", isChecked: false },
      { id: 4, value: "Automated Search", isChecked: false },
      { id: 5, value: "Search via all search engines", isChecked: false },
      { id: 6, value: "All the Above", isChecked: false },
    ],
    answer7: "",
    answer8: [
      { id: 1, value: "“Bengaluru, Karnataka”", isChecked: false },
      { id: 2, value: "intitle:Bengaluru", isChecked: false },
      { id: 3, value: "Bengaluru", isChecked: false },
      { id: 4, value: "inanchor:Bengaluru", isChecked: false },
    ],
    answer9: [
      { id: 1, value: "11,873", isChecked: false },
      { id: 2, value: "300", isChecked: false },
      { id: 3, value: "500", isChecked: false },
      { id: 4, value: "1000", isChecked: false },
    ],
    answer10: "",
    date: new Date().toLocaleString("en-IN"),
    score: "",
    performance: "",
    scores: ["", "", "", "", "", "", "", "", "", ""],
    quiz: "Xray_Sourcing_Basic",
  });

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (enableBeforeUnload) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";

        // Add a console message when the user clicks "OK"
        window.addEventListener("unload", () => {
          fire
            .database()
            .ref("Xray_Sourcing_Basic")
            .child(props.uid)
            .set(state);
        });
      }
    };

    if (enableBeforeUnload) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [enableBeforeUnload, state]);

  useEffect(() => {
    Swal.fire({
      icon: "warning",
      text: "If you exit the Quiz without completing it, your progress will be Submitted.",
    });
  }, []);

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleText = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange = (event, answerName) => {
    let updatedAnswers = [...state[answerName]]; // Copy the original answers array
    updatedAnswers.forEach((answer) => {
      if (answer.value === event.target.value) {
        answer.isChecked = event.target.checked;
      }
    });
    setState({ ...state, [answerName]: updatedAnswers }); // Update the state with the modified answers array
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container>
            <Typography>
              Write xray search to index public data from LinkedIn
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer1"
                onChange={handleText}
                value={state.answer1}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container>
            <Typography>
              To index the current company, which operator will you use?
            </Typography>{" "}
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer2.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer2")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );

      case 2:
        return (
          <Grid container>
            <Typography>
              What does /in denote in site:linkedin.com/in?
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer3.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer3")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );
      case 3:
        return (
          <Grid container>
            <Typography>
              Write a basic xray search string to index profiles from kaggle
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer4"
                onChange={handleText}
                value={state.answer4}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );

      case 4:
        return (
          <Grid container>
            <Typography>
              Write a xray string to index Software Engineers who have their
              mail IDs public
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer5"
                onChange={handleText}
                value={state.answer5}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );

      case 5:
        return (
          <Grid container>
            <Typography>What is the purpose of Xray?</Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer6.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer6")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );

      case 6:
        return (
          <Grid container>
            <Typography>
              Write a xray string to index Software Engineers who have their
              mail IDs public
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer7"
                onChange={handleText}
                value={state.answer7}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );

      case 7:
        return (
          <Grid container>
            <Typography>
              Which of the below is/are syntaxes to look for candidates
              currently in Bengaluru?
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer8.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer8")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );

      case 8:
        return (
          <Grid container>
            <Typography>
              On Google Search, after a xray search it reads to be 11,873
              results. How many of which can you view?
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer9.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer9")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );

      case 9:
        return (
          <Grid container>
            <Typography>
              Write a xray string to index Software Engineers who have their
              mail IDs public
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer10"
                onChange={handleText}
                value={state.answer10}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );
    }
  };

  const handleSubmit = () => {
    setEnableBeforeUnload(false);
    if (props.fill === true) {
      fire.database().ref("Xray_Sourcing_Basic").child(props.uid).set(state);

      Swal.fire({
        icon: "success",
        title: "Awesome! You've completed XRay Sourcing Basics Quiz",
        text: "You'll receive score within 24HRs",
      }).then(function () {
        window.location.href = "/share-experience";
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "OOPS",
        text: "Quiz already attempted",
      }).then(function () {
        window.location.href = "/";
      });
    }
  };

  if (props.attempt)
    return (
      <div>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(index)}</Typography>
                <div>
                  <div>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Back
                    </Button>
                    {activeStep === steps.length - 1 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                      >
                        Finish
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        Next/Skip
                      </Button>
                    )}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>

        <Contact />
      </div>
    );
  else handleSubmit();
}

export default XRaySourcing_Basic_Ques;
