import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import fire from '../../config/fire'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from "react-router-dom"

class Like extends Component {

    constructor(props) {
        super(props);

        this.state = {
            infos: [],
            title: this.props.title
        }
    }

    componentDidMount() {

        let list = []
        fire.database().ref('Blog').once('value', snapshot => {

            snapshot.forEach(snap => {
                list.push(snap.val());
            })

            this.setState({ infos: list })
        })
    }

    render() {

        return (
            <Grid container className="Blog" style={{ marginTop: '-20px', marginLeft: '-10px', marginBottom: '-20px' }}>
                {
                    this.state.infos.length > 0 && this.state.title ?
                        <div className="body">
                            {
                                this.state.infos.map((info) => {
                                    if (this.state.title !== info.title)
                                        return (
                                            <div key={info.title} className="body1" style={{ background: `linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url(${info.image})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} onClick={() => window.open("/blogs/" + info.title, "_blank")} >
                                                <div className="bgTitle">
                                                    <p style={{ fontWeight: 'bold' }}>{info.title}</p>
                                                </div>
                                            </div>
                                        );
                                })
                            }
                        </div> : <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '60px' }}>
                            <CircularProgress thickness={5} />
                        </div>
                }
            </Grid>
        )
    }
}

export default withRouter(Like)