import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import fire from "../../config/fire";
import Swal from "sweetalert2";

const QuizCard = ({ heading, tag, time, uid, desc, imgurl }) => {
  const [detail, setDetail] = useState({});
  const [fill, setFill] = useState(true);

  // useEffect to check if the test is already been taken or not if taken then set the fill state to false;
  useEffect(() => {
    const fetchDataSourcingBasic = async () => {
      if (uid !== null) {
        const snapshot = await fire
          .database()
          .ref("Sourcing_basic")
          .child(uid)
          .once("value");
        if (snapshot.exists()) {
          setFill(false);
        }

        const detailSnapshot = await fire
          .database()
          .ref("Sourcing_basic/" + uid)
          .once("value");
        const list = detailSnapshot.val();
        setDetail(list);
      }
    };

    const fetchDataTechFundamental = async () => {
      if (uid !== null) {
        const snapshot = await fire
          .database()
          .ref("Tech_fundamentals")
          .child(uid)
          .once("value");
        if (snapshot.exists()) {
          setFill(false);
        }

        const detailSnapshot = await fire
          .database()
          .ref("Tech_fundamentals/" + uid)
          .once("value");
        const list = detailSnapshot.val();
        setDetail(list);
      }
    };

    const fetchDataXrayBasicSourcing = async () => {
      if (uid !== null) {
        const snapshot = await fire
          .database()
          .ref("Xray_Sourcing_Basic")
          .child(uid)
          .once("value");
        if (snapshot.exists()) {
          setFill(false);
        }

        const detailSnapshot = await fire
          .database()
          .ref("Xray_Sourcing_Basic/" + uid)
          .once("value");
        const list = detailSnapshot.val();
        setDetail(list);
      }
    };

    const fetchDataXrayAdvanceSourcing = async () => {
      if (uid !== null) {
        const snapshot = await fire
          .database()
          .ref("Xray_Sourcing_Advance")
          .child(uid)
          .once("value");
        if (snapshot.exists()) {
          setFill(false);
        }

        const detailSnapshot = await fire
          .database()
          .ref("Xray_Sourcing_Advance/" + uid)
          .once("value");
        const list = detailSnapshot.val();
        setDetail(list);
      }
    };

    const fetchDataMonkSourcingIndia = async () => {
      if (uid !== null) {
        const snapshot = await fire
          .database()
          .ref("Monk_Sourcing_India")
          .child(uid)
          .once("value");
        if (snapshot.exists()) {
          setFill(false);
        }

        const detailSnapshot = await fire
          .database()
          .ref("Monk_Sourcing_India/" + uid)
          .once("value");
        const list = detailSnapshot.val();
        setDetail(list);
      }
    };

    if (heading === "Sourcing Basics") {
      fetchDataSourcingBasic();
    }
    if (heading === "Tech Fundamentals") {
      fetchDataTechFundamental();
    }
    if (heading === "Xray Basic Sourcing") {
      fetchDataXrayBasicSourcing();
    }
    if (heading === "Xray Advance Sourcing") {
      fetchDataXrayAdvanceSourcing();
    }
    if (heading === "Monk Sourcing (India)") {
      fetchDataMonkSourcingIndia();
    }
  }, [uid]);

  const handleStart = () => {
    if (uid === null) {
      Swal.fire({
        title: "Please Login First!!",
        icon: "error",
      });
    } else {
      if (heading === "Sourcing Basics") {
        window.location.href = "/sourcing-basic-quiz";
      }
      if (heading === "Tech Fundamentals") {
        window.location.href = "/tech-fundamentals-quiz";
      }
      if (heading === "Xray Basic Sourcing") {
        window.location.href = "/xray-basic-sourcing-quiz";
      }
      if (heading === "Xray Advance Sourcing") {
        window.location.href = "/xray-advance-sourcing-quiz";
      }
      if (heading === "Monk Sourcing (India)") {
        window.location.href = "/monk-sourcing-india";
      }
    }
  };

  return (
    <>
      <div className="quiz_card">
        <div className="quiz_data">
          <div className="image">
            <img src={imgurl} alt="image" />
          </div>
          <div className="quiz_content">
            <div className="quiz_info">
              <div >{time}</div>
              <div>|</div>
              <div>{tag}</div>
            </div>
            <div className="quiz_heading">{heading}</div>
            <div className="quiz_detail">{desc}</div>
          </div>
        </div>

        {fill === true ? (
          <div className="quiz_button" onClick={handleStart}>
            Take Quiz
          </div>
        ) : (
          <div
            className="quiz_button"
            onClick={() => {
              window.location.href = `/scorecard/${uid}`;
            }}
          >
            See Scorecard
          </div>
        )}
      </div>
    </>
  );
};

export default QuizCard;
