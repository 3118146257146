import React, { useEffect, useState } from "react";
import fire from "../../config/fire";
import Swal from "sweetalert2";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Contact from "../Contact";
import { Checkbox } from "@material-ui/core";

function getSteps() {
  return [
    "Question 1",
    "Question 2",
    "Question 3",
    "Question 4",
    "Question 5",
    "Question 6",
    "Question 7",
    "Question 8",
    "Question 9",
    "Question 10",
  ];
}

function Monk_Sourcing_India_Ques(props) {
  const [enableBeforeUnload, setEnableBeforeUnload] = useState(true);
  const [state, setState] = React.useState({
    name: props.name,
    email: props.email,
    uid: props.uid,
    answer1: "",
    answer2: "",
    answer3: "",
    answer5: [
      {
        id: 1,
        value:
          "Both LinkedIn + Naukri : Gerunds & Plurals of a keyword to be used as synonyms",
        isChecked: false,
      },
      {
        id: 2,
        value:
          "Xray : Gerunds & plurals of a keyword need not be used as synonyms",
        isChecked: false,
      },
      {
        id: 3,
        value:
          "LinkedIn only : Gerunds & Plurals of a keyword to be used as synonyms",
        isChecked: false,
      },
      {
        id: 4,
        value:
          "Naukri only : Gerunds & Plurals of a keyword to be used as synonyms",
        isChecked: false,
      },
      {
        id: 5,
        value:
          "Naukri : Gerunds & plurals of a keyword need not be used as synonyms",
        isChecked: false,
      },
      {
        id: 6,
        value:
          "All 3, Xray + LinkedIn + Naukri : Gerunds & Plurals of a keyword to be used as synonyms",
        isChecked: false,
      },
      {
        id: 7,
        value:
          "Xray only : Gerunds & Plurals of a keyword to be used as synonyms",
        isChecked: false,
      },
      {
        id: 8,
        value:
          "All 3, Xray + LinkedIn + Naukri : Gerunds & Plurals of a keyword need not be used as synonyms",
        isChecked: false,
      },
    ],
    answer4: "",
    answer6: "",
    answer7: "",
    answer8: "",
    answer9: "",
    answer10: "",
    date: new Date().toLocaleString("en-IN"),
    score: "",
    performance: "",
    scores: ["", "", "", "", "", "", "", "", "", ""],
    quiz: "Monk_Sourcing_India",
  });

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (enableBeforeUnload) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";

        // Add a console message when the user clicks "OK"
        window.addEventListener("unload", () => {
          fire
            .database()
            .ref("Monk_Sourcing_India")
            .child(props.uid)
            .set(state);
        });
      }
    };

    if (enableBeforeUnload) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [enableBeforeUnload, state]);

  useEffect(() => {
    Swal.fire({
      icon: "warning",
      text: "If you exit the Quiz without completing it, your progress will be Submitted.",
    });
  }, []);

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    console.log(state);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleText = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange = (event, answerName) => {
    let updatedAnswers = [...state[answerName]]; // Copy the original answers array
    updatedAnswers.forEach((answer) => {
      if (answer.value === event.target.value) {
        answer.isChecked = event.target.checked;
      }
    });
    setState({ ...state, [answerName]: updatedAnswers }); // Update the state with the modified answers array
  };
  // onChange={(event) => handleChange(event, "answer1")}

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container>
            <Typography>
              Write two probabilistic searches to get FullStack developers with
              skills like angular, node, express, SQL database. (Ensure each
              search gives unique data, without compensating on the quality of
              the results)
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer1"
                onChange={handleText}
                value={state.answer1}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );

      case 1:
        return (
          <Grid container>
            <Typography>
              What is the top search you can write on NAUKRI to get Java +
              ReactJS fullstack developers. (If you are going to switch on any
              specific features on naukri, do mention that in detail along with
              the search)
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer2"
                onChange={handleText}
                value={state.answer2}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );

      case 2:
        return (
          <Grid container>
            <Typography>
              How to get UI Developers from Naukri who do not have UI or any
              prominent UI/frontend related designations on their resumes. Frame
              or choose any UI related skills as per your choice
              <br />
              (If you are going to switch on any specific features on naukri, do
              mention that in detail along with the search)
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer3"
                onChange={handleText}
                value={state.answer3}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );

      case 3:
        return (
          <Grid container>
            <Typography>
              You’re hiring for a top product company from NAUKRI and you’re
              looking for a Java backend developer (no other skill
              specifications). What will your search look like, how will you
              ensure you avoid irrelevant candidate profiles..
              <br />
              The search you write, should be highly qualitative & quantitative.
              (If you are going to switch on any specific features on naukri, do
              mention that in detail along with the search)
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer4"
                onChange={handleText}
                value={state.answer4}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );

      case 4:
        return (
          <Grid container>
            <Typography>
              Which of the below holds true for linkedin, Xray and naukri
            </Typography>{" "}
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer5.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer5")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );

      case 5:
        return (
          <Grid container>
            <Typography>
              Write xray to get candidates from linkedin who are currently
              working as ML Engineers and their tenure in the current company
              must be between 2-4 years.
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer6"
                onChange={handleText}
                value={state.answer6}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );

      case 6:
        return (
          <Grid container>
            <Typography>
              Write a search to get android developers, we need only native
              android developers. Your search needs to ensure hybrid developers
              are negated as much as possible.
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer7"
                onChange={handleText}
                value={state.answer7}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );
      case 7:
        return (
          <Grid container>
            <Typography>
              You understand that, Google shows only few hundred search results.
              Now, you have to get UI developers via xray search. How will you
              write multiple searches to get the most qualitative UI developers
              & as many as possible? (You can write as many searches as
              possible, but ensure each search gives unique data only. Keep in
              mind, the data slicing techniques)
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer8"
                onChange={handleText}
                value={state.answer8}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );
      case 8:
        return (
          <Grid container>
            <Typography>
              Write a search to index companies from LinkedIn who are
              headquartered at Bangalore and are founded in 2016
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer9"
                onChange={handleText}
                value={state.answer9}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );
      case 9:
        return (
          <Grid container>
            <Typography>
              Write 5 unique searches on normal LinkedIn to get backend Python
              Django developers with Microservices and cloud experience.
              <br />
              Ensure, your 5 searches are ranked in a manner the first one is
              the most qualitative one & 5th being least. Also, none of the
              searches should have overlapping data.
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer10"
                onChange={handleText}
                value={state.answer10}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );
    }
  };

  const handleSubmit = () => {
    setEnableBeforeUnload(false);
    if (props.fill === true) {
      fire.database().ref("Monk_Sourcing_India").child(props.uid).set(state);

      Swal.fire({
        icon: "success",
        title: "Awesome! You've completed Monk Sourcing (India)",
        text: "You'll receive score within 24HRs",
      }).then(function () {
        window.location.href = "/share-experience";
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "OOPS",
        text: "Quiz already attempted",
      }).then(function () {
        window.location.href = "/";
      });
    }
  };

  if (props.attempt)
    return (
      <div>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(index)}</Typography>
                <div>
                  <div>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Back
                    </Button>
                    {activeStep === steps.length - 1 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                      >
                        Finish
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        Next/Skip
                      </Button>
                    )}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>

        <Contact />
      </div>
    );
  else handleSubmit();
}

export default Monk_Sourcing_India_Ques;
