import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import fire from "../../config/fire";
import { useParams } from "react-router-dom";
import Avatar from "react-avatar";
import Header from "../Header";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { IconButton } from "@material-ui/core";
import Loader from "../../Loader";
import ProfileScoreCard from "./ProfileScoreCard";
import Swal from "sweetalert2";

const ProfilePage = ({ uid }) => {
  const { id } = useParams();
  const [detail, setDetail] = useState({});
  const [info, setInfo] = useState(false);
  const [percentage, setPercentage] = useState([]);

  useEffect(() => {
    if (info === "invalid user") {
      Swal.fire({
        icon: "error",
        title: "No user Found",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/"; // Navigate to the home route
        }
      });
    }
  }, [info]);

  useEffect(() => {
    const fetchData = async () => {
      if (id !== null) {
        const snapshot = await fire
          .database()
          .ref("Competition")
          .child(id)
          .once("value");
        if (snapshot.exists()) {
          const detailSnapshot = await fire
            .database()
            .ref("Competition/" + id)
            .once("value");
          const list = detailSnapshot.val();
          setDetail(list);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await fire
          .database()
          .ref("Details")
          .child(id)
          .once("value");
        if (snapshot.exists()) {
          const data = snapshot.val();
          setInfo(data);
        } else {
          setInfo("invalid user");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run the effect once
  

  useEffect(() => {
    //  this code is to calculate the overall standings of the user
    const fetchData = () => {
      const list = [];
      const holder = {};
      const obj2 = [];

      fire
        .database()
        .ref("Competition")
        .on("value", (snapshot) => {
          snapshot.forEach((snap) => {
            if (snap.val().score !== "") list.push(snap.val());
          });

          list.forEach(function (d) {
            if (holder.hasOwnProperty(d.uid + " " + d.name)) {
              holder[d.uid + " " + d.name] =
                holder[d.uid + " " + d.name] + parseFloat(d.score);
            } else {
              holder[d.uid + " " + d.name] = parseFloat(d.score);
            }
          });

          for (let p in holder) {
            obj2.push({
              uid: p.substr(0, p.indexOf(" ")),
              score: holder[p],
              name: p.substr(p.indexOf(" ") + 1, p.indexOf(" ")),
            });
          }

          const users = obj2.sort(function (a, b) {
            return parseFloat(b.score) - parseFloat(a.score);
          });

          const userIndex = users.findIndex((item) => item.uid === id);
          const totalUsers = users.length; // Total number of users

          const topPercentage = (userIndex + 1 / totalUsers) * 100;
          //   const roundedTopPercentage = Math.round(topPercentage);

          const userCategory = categorizeUser(topPercentage);

          setPercentage(userCategory);
        });
    };

    fetchData();
  }, []);

  const categorizeUser = (userPercentage) => {
    if (userPercentage >= 90) {
      return "Top 1%";
    } else if (userPercentage >= 80) {
      return "Top 10%";
    } else if (userPercentage >= 70) {
      return "Top 20%";
    } else if (userPercentage >= 50) {
      return "Top 50%";
    } else {
      return "Below 50%";
    }
  };

  const copyLink = () => {
    try {
      const currentURL = window.location.href;
      navigator.clipboard.writeText(currentURL);
      Swal.fire({
        icon: "success",
        title: "Link Copied to Clipboard",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Failed to Copy",
      });
    }
  };

  return (
    <>
      <Header />
      <Loader />
      {info && info !== "invalid user" && (
        <div className="profilecontainer">
          <div className="profile_header">
            {!info.photoURL ? <Avatar
              name={info.name}
              alt={info.name}
              round={true}
              size={120}
            />
            :
            <Avatar
              name={info.name}
              alt={info.displayName}
              src={
                info.photoURL
              }
              round={true}
              size={120}
            />}
            <div className="profile_header_heading">
              <div className="profile_name">
                <div>{info.name}</div>
                <div className="linkedinIcon">
                  <IconButton
                   onClick={() => {
                    const linkedinLink = info.linkedin.startsWith('https://') ? info.linkedin : `https://${info.linkedin}`;
                    window.open(linkedinLink, '_blank');
                  }}
                    className="linkedin"
                  >
                    <LinkedInIcon fontSize="large" />
                  </IconButton>
                </div>
              </div>

              <div
                className="profile_header_subheading"
                style={{ fontWeight: "500", fontStyle: "italic" }}
              >
                {info.experience}+ years Experience
              </div>

              <div
                className="profilepagedescription"
                style={{ fontWeight: "400" }}
              >
                {info.description}
              </div>

              <div style={{ padding: "1rem" }}>
                <button onClick={copyLink} className="my_dashboard_link">
                  {id === uid ? "My Scorecard Link" : "Copy Scorecard Link"}
                </button>
              </div>
            </div>
          </div>

          <div className="profile_body">
            <div
              className="profile_body_container"
              style={{ backgroundImage: `url(/images/bg.svg)` }}
            >
              <div className="scorecard_container">
                {/* <ProfileScoreCard
                  heading="#Overall Rank"
                  content={percentage}
                /> */}
                {/* <ProfileScoreCard
                  heading="Total Marks Obtained"
                  content={`${detail.score}/10`}
                /> */}
                <ProfileScoreCard
                  heading="Sourcing Basic"
                  score="5"
                  uid={uid}
                />
                <ProfileScoreCard
                  heading="Tech Fundamentals"
                  score="10"
                  uid={uid}
                />
                <ProfileScoreCard
                  heading="Xray Basic Sourcing"
                  score="10"
                  uid={uid}
                />
                <ProfileScoreCard
                  heading="Xray Advance Sourcing"
                  score="25"
                  uid={uid}
                />
                <ProfileScoreCard
                  heading="Monk Sourcing (India)"
                  score="50"
                  uid={uid}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfilePage;
