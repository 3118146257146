import React from "react";
import Header from "../Header";
import SubHeader from "./QuizHeader";
import NewQuizBody from "./NewQuizBody";
import Info from "./Info";
import Footer from "./QuizFooter";
import QuizDetail from "./QuizDetail";

const NewQuizPage = (props) => {
  return (
    <>
      <Header />
      <SubHeader />
      
      <Info />
      <NewQuizBody uid={props.uid}/>
      <QuizDetail />
      <Footer />
    </>
  );
};

export default NewQuizPage;
