import React, { useContext } from "react";
import { UserContext } from "./Providers/UserProvider";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Main from "./Main";
import Quiz from "./Components/Quiz";
import JavaQuiz from "./Components/Quizzes/JavaQuiz";
import Dashboard from "./Admin Stuff/Dashboard";
import Share from "./Components/Share";
import UIQuiz from "./Components/Quizzes/UIQuiz";
import DevopsQuiz from "./Components/Quizzes/DevopsQuiz";
import BlogContent from "./Components/Blog/BlogContent";
import CompanyDash from "./Company Admin/CompanyDash";
import Proceed from "./Proceed";
import RecroQuiz from "./Components/CompanyQuizzes/RecroQuiz";
import TricogQuiz from "./Components/CompanyQuizzes/TricogQuiz";
import TestQuiz from "./Components/CompanyQuizzes/TestQuiz";
import NewQuizPage from "./Components/NewQuiz/NewQuizPage";
import CompetitionQuiz from "./Components/Quizzes/CompetitionQuiz";
import ProfilePage from "./Components/UserProfile/ProfilePage";
import Sourcing_Basic_Quiz from "./Components/Quizzes/Sourcing_Basics_Quiz";
import Tech_Fundamental_Quiz from "./Components/Quizzes/Tech_Fundamental_Quiz";
import XRay_Sourcing_Basics_Quiz from "./Components/Quizzes/XRaySourcing_Basic_Quiz";
import XRay_Sourcing_Advance_Quiz from "./Components/Quizzes/XRaySourcing_Advance_Quiz";
import Monk_Sourcing_India_Quiz from "./Components/Quizzes/Monk_Sourcing_India_Quiz"

const SubApp = () => {
  const user = useContext(UserContext);

  if (user)
    return (
      <Router>
        <Switch>
          {/* <Route exact path="/" component={() => <Main uid={user.uid} />} /> */}
          <Route
            exact
            path="/"
            component={() => <NewQuizPage uid={user.uid} />}
          />
          <Route
            exact
            path="/competition"
            component={() => <NewQuizPage uid={user.uid} />}
          />
          <Route exact path="/blogs/:title" component={BlogContent} />
          <Route exact path="/share-experience" component={() => <Share />} />
          <Route
            exact
            path="/boolean-basics-quiz"
            component={() => (
              <Quiz uid={user.uid} name={user.displayName} email={user.email} />
            )}
          />
          <Route
            exact
            path="/java-stack-quiz"
            component={() => (
              <JavaQuiz
                uid={user.uid}
                name={user.displayName}
                email={user.email}
              />
            )}
          />
          <Route
            exact
            path="/competition-quiz"
            component={() => (
              <CompetitionQuiz
                uid={user.uid}
                name={user.displayName}
                email={user.email}
              />
            )}
          />
          <Route
            exact
            path="/ui-quiz"
            component={() => (
              <UIQuiz
                uid={user.uid}
                name={user.displayName}
                email={user.email}
              />
            )}
          />
          <Route
            exact
            path="/sourcing-basic-quiz"
            component={() => (
              <Sourcing_Basic_Quiz
                uid={user.uid}
                name={user.displayName}
                email={user.email}
              />
            )}
          />

          <Route
            exact
            path="/tech-fundamentals-quiz"
            component={() => (
              <Tech_Fundamental_Quiz
                uid={user.uid}
                name={user.displayName}
                email={user.email}
              />
            )}
          />

          <Route
            exact
            path="/xray-basic-sourcing-quiz"
            component={() => (
              <XRay_Sourcing_Basics_Quiz
                uid={user.uid}
                name={user.displayName}
                email={user.email}
              />
            )}
          />

          <Route
            exact
            path="/xray-advance-sourcing-quiz"
            component={() => (
              <XRay_Sourcing_Advance_Quiz
                uid={user.uid}
                name={user.displayName}
                email={user.email}
              />
            )}
          />

          <Route
            exact
            path="/monk-sourcing-india"
            component={() => (
              <Monk_Sourcing_India_Quiz
                uid={user.uid}
                name={user.displayName}
                email={user.email}
              />
            )}
          />

          <Route
            exact
            path="/devops-quiz"
            component={() => (
              <DevopsQuiz
                uid={user.uid}
                name={user.displayName}
                email={user.email}
              />
            )}
          />
          <Route
            exact
            path="/recro-hiring"
            component={() => (
              <RecroQuiz
                uid={user.uid}
                name={user.displayName}
                email={user.email}
              />
            )}
          />
          <Route
            exact
            path="/tricog-health-hiring"
            component={() => (
              <TricogQuiz
                uid={user.uid}
                name={user.displayName}
                email={user.email}
              />
            )}
          />
          <Route
            exact
            path="/monk-scholarship"
            component={() => (
              <TestQuiz
                uid={user.uid}
                name={user.displayName}
                email={user.email}
              />
            )}
          />
          <Route
            exact
            path="/admin"
            component={() => (
              <Dashboard
                uid={user.uid}
                name={user.displayName}
                photoURL={user.photoURL}
              />
            )}
          />
          <Route
            exact
            path="/company-admin"
            component={() => (
              <CompanyDash
                uid={user.uid}
                name={user.displayName}
                photoURL={user.photoURL}
              />
            )}
          />
          <Route
            exact
            path="/scorecard/:id"
            component={() => <ProfilePage uid={user.uid} />}
          />
        </Switch>
      </Router>
    );
  else
    return (
      <Router>
        <Switch>
          {/* <Route exact path="/" component={() => <Main uid={null} />} /> */}
          <Route exact path="/" component={() => <NewQuizPage uid={null} />} />

          <Route
            exact
            path="/competition"
            component={() => <NewQuizPage uid={null} />}
          />
          <Route exact path="/blogs/:title" component={BlogContent} />
          <Route exact path="/recro-hiring" component={Proceed} />
          <Route exact path="/tricog-health-hiring" component={Proceed} />
          <Route exact path="/monk-scholarship" component={Proceed} />
          <Route
            exact
            path="/scorecard/:id"
            component={() => <ProfilePage uid={null} />}
          />
        </Switch>
      </Router>
    );
};

export default SubApp;

// #rank (2023-24) Marks, Boolean, Sorcing, Tech, ChatGpt
