import React, { Component } from 'react';
import Countdown from 'react-countdown';
import JavaQues from './JavaQues'
import Grid from '@material-ui/core/Grid';
import fire from '../../config/fire'

class JavaQuiz extends Component {

    constructor(props) {
        super(props)

        this.state = {
            fill: true,
            attempt: 1
        }
    }

    handleText = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    };

    handleAttempt = () => {
        this.setState({
            attempt: 0
        });
    };

    componentDidMount() {
        fire
            .database()
            .ref("Java_stack")
            .once('value', snapshot => {
                if (snapshot.hasChild(this.props.uid))
                    this.setState({
                        fill: false
                    })
            });
    }

    renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            this.handleAttempt()
            return <h2>Time's Up!!</h2>
        } else {
            // Render a countdown
            return (
                <Grid item xs={12} className="clock">
                    <h1>{minutes}:{seconds}</h1>
                </Grid>
            )
        }
    };

    render() {

        return (
            <div>
                {
                    this.state.fill ?
                        <Grid container className="quiz">
                            <Grid item xs={12}>
                                <Countdown
                                    date={Date.now() + 600000}
                                    renderer={this.renderer}
                                /></Grid>
                            <Grid item xs={12} md={7} className="questions">
                                <JavaQues uid={this.props.uid} fill={this.state.fill} attempt={this.state.attempt} name={this.props.name} email={this.props.email} />
                            </Grid>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={12} style={{ padding: '10px' }}>
                                <p>Quiz Already Attempted</p>
                            </Grid>
                        </Grid>
                }
            </div>
        );
    }
}

export default JavaQuiz;