import React from 'react';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Loader from '../Loader'
import HomeIcon from '@material-ui/icons/Home';
import Avatar from '@material-ui/core/Avatar';
import { auth } from "../config/fire";
import Graph from './Graph'
import Admin from './Admin'
import Unscore from './Unscore'
import BlogForm from './BlogForm'
import CompanyScore from './CompanyScore'

function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`admin-tabpanel-${index}`}
            aria-labelledby={`admin-tab-${index}`}
            className="admin-tabpanel"
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

function Dashboard(props) {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (props.uid != null && (props.uid === "RTaq0vnUAmVzpsXJ4QqhK89g1Yk1" || props.uid === "JNnMXlsU5vTLR8dSUVPZT88OQM82" || props.uid === "l4NQtyTfI9PGGf3QvuaFbyO0frB2" || props.uid === "3emTXgEkN1RmlQzYGvLZcHtrBVr1" || props.uid === "EFIgL9gWeRhWcwoGLrgNbpottR92" || props.uid === "mxRpITJTqxgb0EJi5HFKWQSJjTe2"))
        return (
            <div>
                <Loader />
                <Grid container style={{ display: 'inline-flex', backgroundColor: 'cornflowerblue' }}>
                    <Grid item xs={6} style={{ alignSelf: 'center', display: 'inline-flex' }}>
                        <h3 className="name" style={{ float: 'left', color: 'white' }}>Welcome {props.name.substr(0, props.name.indexOf(' '))}</h3>
                    </Grid>
                    <Grid item xs={6} className="logout" style={{ alignSelf: 'center' }}>
                        <div style={{ float: 'right', display: 'inline-flex' }}>
                            <Avatar style={{ marginRight: '10px' }} alt={props.name} src={props.photoURL || "https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png"} />
                            <Button onClick={() => { auth.signOut() }} size="small" variant="contained" color="primary">Logout</Button>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ padding: '15px', marginBottom: '30px' }}>
                            <Button
                                style={{ float: 'right' }}
                                variant="contained"
                                color="primary"
                                href="/"
                                startIcon={<HomeIcon />}
                            >
                                Go to Home</Button>
                        </div>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} style={{ padding: '20px' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="admin-tabs" className="admin-tabs" >
                            <Tab label="All Attempts" />
                            <Tab label="Not Scored" />
                            <Tab label="Visualization" />
                            <Tab label="Add Blog" />
                            <Tab label="Company Hiring" />
                        </Tabs>

                        <TabPanel value={value} index={0}>
                            <Admin />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Unscore />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Graph />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <BlogForm />
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <CompanyScore />
                        </TabPanel>
                    </Grid>
                </Grid>
            </div>
        )
    else
        return (
            <div>
                <Loader />
                You're not an admin
            </div >
        )
}

export default Dashboard;
