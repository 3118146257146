import React from 'react'

const QuizFooter = () => {
  return (
    <div className="footer_container" >
        <div className="footer_image">
        <img src="/images/logo.png" className="logo" />
        </div>

        <div className="footer_desc">
        India’s largest community-driven Recruiting Ecosystem that combines years of industry experience with tech expertise to impact recruiters and recruiting.
        </div>

        <div className="footer_sub_desc">© 2022 Fellowapp HR Solutions(OPC) Private Limited</div>
    </div>
  )
}

export default QuizFooter