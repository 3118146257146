import React from 'react'
import fire from '../config/fire'
import Swal from "sweetalert2";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import Contact from './Contact'

function getSteps() {
    return ['Question 1', 'Question 2', 'Question 3', 'Question 4', 'Question 5'];
}

function Questions(props) {

    const [state, setState] = React.useState({
        name: props.name,
        email: props.email,
        uid: props.uid,
        answer1: [
            { id: 1, value: '“Java” AND “Spring” AND “Hibernate” AND “Linux”', isChecked: false },
            { id: 2, value: '(Java) AND (Spring AND hibernate) AND (Linux)', isChecked: false },
            { id: 3, value: 'Java AND Spring AND Hibernate AND Linux', isChecked: false },
            { id: 4, value: '(Java) AND (Spring) AND (Hibernate) AND (Linux)', isChecked: false }
        ],
        answer2: "",
        answer3: "",
        answer4: "",
        answer5: "",
        date: new Date().toLocaleString("en-IN"),
        score: "",
        scores: ["", "", "", "", ""],
        quiz: "Boolean_basics"
    })

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleText = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };

    const handleChange = (event) => {
        let fruites = state.answer1
        fruites.forEach(fruite => {
            if (fruite.value === event.target.value)
                fruite.isChecked = event.target.checked
        })
        setState({ ...state, answer1: fruites })
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Grid container>
                        <Typography>Out of the below searches which is/are technically (as per Boolean usage) correct ?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                {
                                    state.answer1.map(data => {
                                        return (
                                            <div>
                                                <FormControlLabel control={<Checkbox key={data.id} onChange={handleChange} checked={data.isChecked} value={data.value} />} label={data.value} />
                                            </div>
                                        )
                                    })
                                }
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 1:
                return (
                    <Grid container>
                        <Typography>If you’re looking for someone with multi thread experience for a Java developer role & assume, you have written a boolean string which looks like : </Typography>
                        <br />
                        <Typography><b>Java AND Spring AND Hibernate AND SQL</b>. Now to include multi threading which of the below you’d use : </Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer2" value={state.answer2} onChange={handleText}>
                                    <FormControlLabel value='(Multithread OR “Multi thread”)' control={<Radio />} label='(Multithread OR “Multi thread”)' />
                                    <FormControlLabel value="(Multithread OR Multi thread)" control={<Radio />} label="(Multithread OR Multi thread)" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 2:
                return (
                    <Grid container>
                        <Grid item xs={12}><Typography>Write a Boolean for the below skill set : </Typography></Grid>
                        <Grid item xs={12}>
                            <ul>
                                <li>Experience as Java developer</li>
                                <li>Good exposure to hibernate, springMVC</li>
                                <li>Experience with webservices</li>
                                <li>Should have experience with both SQL & Redis or good with MongoDB</li>
                            </ul>
                        </Grid>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <TextField label="Enter your answer Here" variant="outlined" name="answer3"
                                onChange={handleText} value={state.answer3} fullWidth multiline rowsMax={5} />
                        </Grid>
                    </Grid>
                )
            case 3:
                return (
                    <Grid container>
                        <Typography>Which of the below is wrong ?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer4" value={state.answer4} onChange={handleText}>
                                    <FormControlLabel value="(Java AND Spring OR Struts)" control={<Radio />} label="(Java AND Spring OR Struts)" />
                                    <FormControlLabel value="Java AND Spring AND Struts" control={<Radio />} label="Java AND Spring AND Struts" />
                                    <FormControlLabel value="(Java AND Spring AND Struts)" control={<Radio />} label="(Java AND Spring AND Struts)" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 4:
                return (
                    <Grid container>
                        <Typography>Which of the below searches you’d use to find Asp.net developers ?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer5" value={state.answer5} onChange={handleText}>
                                    <FormControlLabel value='Asp.net AND (Webservices OR Web service OR Rest) AND “SQL Server”' control={<Radio />} label='Asp.net AND (Webservices OR Web service OR Rest) AND “SQL Server”' />
                                    <FormControlLabel value="Asp.net AND (Webservices OR Web service OR Rest) AND SQL Server" control={<Radio />} label="Asp.net AND (Webservices OR Web service OR Rest) AND SQL Server" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
        }
    }

    const handleSubmit = () => {

        console.log(state.answer1)

        if (props.fill === true) {
            fire
                .database()
                .ref("Boolean_basics")
                .child(props.uid)
                .set(state)

            Swal.fire({
                icon: "success",
                title: "Awesome! You've completed Boolean basics Test",
                text: "You'll receive score within 24HRs"
            }).then(function () {
                window.location.href = '/share-experience'
            });
        }
        else {
            Swal.fire({
                icon: "error",
                title: "OOPS",
                text: "Quiz already attempted"
            }).then(function () {
                window.location.href = '/'
            });
        }
    };

    if (props.attempt)
        return (
            <div>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                <Typography>{getStepContent(index)}</Typography>
                                <div>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        >Back</Button>
                                        {activeStep === steps.length - 1 ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                            >Finish</Button>
                                            :
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                            >Next/Skip</Button>
                                        }
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                <Contact />
            </div>
        )
    else
        handleSubmit()
}

export default Questions