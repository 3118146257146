import React, { useEffect, useState } from "react";
import fire from "../../config/fire";
import Swal from "sweetalert2";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Contact from "../Contact";
import { Checkbox } from "@material-ui/core";

function getSteps() {
  return [
    "Question 1",
    "Question 2",
    "Question 3",
    "Question 4",
    "Question 5",
    "Question 6",
    "Question 7",
    "Question 8",
    "Question 9",
    "Question 10",
  ];
}

function XRaySourcing_Advance_Ques(props) {

  const [enableBeforeUnload, setEnableBeforeUnload] = useState(true);

  const [state, setState] = React.useState({
    name: props.name,
    email: props.email,
    uid: props.uid,
    answer1: "",
    answer2: "",
    answer3: "",
    answer4: [
      {
        id: 1,
        value: "“2..3 (yrs |  years * exp | experience)”",
        isChecked: false,
      },
      {
        id: 2,
        value: "“2..3 (yrs |  years | exp | experience)”",
        isChecked: false,
      },
      {
        id: 3,
        value: " 2..3 AROUND(3) (yrs |  years * exp | experience)",
        isChecked: false,
      },
      {
        id: 4,
        value: "“2..3 AROUND(3) (yrs |  years * exp | experience)”",
        isChecked: false,
      },
    ],
    answer5: "",
    answer6: "",
    answer7: "",
    answer8: "",
    answer9: "",
    answer10: "",
    date: new Date().toLocaleString("en-IN"),
    score: "",
    performance: "",
    scores: ["", "", "", "", "", "", "", "", "", ""],
    quiz: "Xray_Sourcing_Advance",
  });

  useEffect(() => {
      const handleBeforeUnload = (e) => {
          if (enableBeforeUnload) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";

        // Add a console message when the user clicks "OK"
        window.addEventListener("unload", () => {
          fire
            .database()
            .ref("Xray_Sourcing_Advance")
            .child(props.uid)
            .set(state);
        });
      }
    };

    if (enableBeforeUnload) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [enableBeforeUnload, state]);

  useEffect(() => {
    Swal.fire({
      icon: "warning",
      text: "If you exit the Quiz without completing it, your progress will be Submitted.",
    });
  }, []);

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleText = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange = (event, answerName) => {
    let updatedAnswers = [...state[answerName]]; // Copy the original answers array
    updatedAnswers.forEach((answer) => {
      if (answer.value === event.target.value) {
        answer.isChecked = event.target.checked;
      }
    });
    setState({ ...state, [answerName]: updatedAnswers }); // Update the state with the modified answers array
  };
  
  

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container>
            <Typography>
              Write a xray search to get engineering managers from Bengaluru who
              do not work currently (You cannot use : Looking for job, open for
              job or any similar words)
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer1"
                onChange={handleText}
                value={state.answer1}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );

      case 1:
        return (
          <Grid container>
            <Typography>
              How to index a candidate who previously worked at Oracle via an
              xray search?
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer2"
                onChange={handleText}
                value={state.answer2}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );

      case 2:
        return (
          <Grid container>
            <Typography>
              Write a X-ray : To index candidates who currently work at a
              company based in Benguluru, but their current location is of a
              different city.
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer3"
                onChange={handleText}
                value={state.answer3}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );

      case 3:
        return (
          <Grid container>
            <Typography>
              Which of the below are right strings? (It could be one or more
              from the below)
            </Typography>{" "}
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer4.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer4")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );

      case 4:
        return (
          <Grid container>
            <Typography>
              Via Google search, you have to search candidates with skills Java,
              Spring & a city of your preferred choice. But, your xray should
              not have /in of linkedin.com/in, it should be site:linkedin.com.
              <br />
              Now, write a search string which will give you only candidate
              data. (Note: The search result should give you more than 2 pages
              data)
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer5"
                onChange={handleText}
                value={state.answer5}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );

      case 5:
        return (
          <Grid container>
            <Typography>
              Write an xray : To get Product Managers who previously worked as
              software engineers.
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer6"
                onChange={handleText}
                value={state.answer6}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );

      case 6:
        return (
          <Grid container>
            <Typography>
              You understand that, Google shows only a few hundred search
              results. Now, you have to get UI developers via xray search. Write
              two searches to get the most qualitative UI engineers & skill set
              is of your choice?
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer7"
                onChange={handleText}
                value={state.answer7}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );

      case 7:
        return (
          <Grid container>
            <Typography>
              Write Xray string to get candidates with experience range 5-7
              years for UI Developers.
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer8"
                onChange={handleText}
                value={state.answer8}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );

      case 8:
        return (
          <Grid container>
            <Typography>
              Write xray to get candidates who worked as SDE III during
              2018-2020 and currently working as staff or principal
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer9"
                onChange={handleText}
                value={state.answer9}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );

      case 9:
        return (
          <Grid container>
            <Typography>
              Write Xray : Get candidates who are software engineers from
              Amazon, who have 500 to 700 connections.
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer10"
                onChange={handleText}
                value={state.answer10}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );
    }
  };

  const handleSubmit = () => {
    setEnableBeforeUnload(false)
    if (props.fill === true) {
      fire.database().ref("Xray_Sourcing_Advance").child(props.uid).set(state);

      Swal.fire({
        icon: "success",
        title: "Awesome! You've completed XRay Sourcing Advance Quiz",
        text: "You'll receive score within 24HRs",
      }).then(function () {
        window.location.href = "/share-experience";
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "OOPS",
        text: "Quiz already attempted",
      }).then(function () {
        window.location.href = "/";
      });
    }
  };

  if (props.attempt)
    return (
      <div>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(index)}</Typography>
                <div>
                  <div>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Back
                    </Button>
                    {activeStep === steps.length - 1 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                      >
                        Finish
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        Next/Skip
                      </Button>
                    )}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>

        <Contact />
      </div>
    );
  else handleSubmit();
}

export default XRaySourcing_Advance_Ques;
