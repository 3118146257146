import React, { Component } from 'react'
import fire from '../config/fire'
import { Button, Grid, TextField } from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/Restore';
import Drawing from './Drawing'
import moment from 'moment'

class AdminView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            quiz: this.props.quiz,
            detail: [],
            info: [],
            row: 5,
            search: "",
            length: ""
        }
    }

    handleFilter = (event) => {

        let val = document.getElementById('search').value
        this.setState({
            search: val
        });
    }

    handleReset = (event) => {
        document.getElementById('search').value = ""
        this.setState({
            search: ""
        });
    }

    componentDidMount() {

        if (this.state.quiz)
            fire.database().ref(this.state.quiz).once('value', snapshot => {
                let list = []
                let t

                t = Object.keys(snapshot.val()).length

                this.setState({ length: t })

                snapshot.forEach(snap => {
                    list.push(snap.val());
                })

                let ans = list.sort(function (a, b) {

                    return moment(b.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x") - moment(a.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x")
                });

                this.setState({ detail: ans })
            })

        fire.database().ref("Details").once('value', snapshot => {
            let i = []

            snapshot.forEach(snap => {
                i.push(snap.val());
            })

            this.setState({ info: i })
        })
    }

    render() {
        return (
            <div>
                <Grid container>
                    <Grid item xs={12} md={6} style={{ alignSelf: 'center' }}>
                        <p className="counter"><b>{this.state.length}</b> Have taken the Assessment.</p>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ alignSelf: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <TextField variant="outlined" id="search" size="small" placeholder="Type name/email/mobile" label="Search" />
                            <Button variant="contained" color="primary" size="small" onClick={this.handleFilter}>Search</Button>
                            <Button onClick={this.handleReset}><RestoreIcon /></Button>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} style={{ overflowX: 'auto', marginTop: '20px', padding: '10px' }}>
                        <table className="admin-table">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Attended on</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Answers</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.detail.map(i => {
                                    if (this.state.search !== "" && (i.name.includes(this.state.search.toLowerCase()) || i.name.includes(this.state.search.toUpperCase()) || i.email.includes(this.state.search.toLowerCase()) || i.email.includes(this.state.search.toUpperCase())))
                                        return (
                                            <tr key={i.uid}>
                                                <td>{i.date}</td>
                                                <td>{i.name}</td>
                                                <td>{i.email}</td>
                                                <td><Drawing quiz={i.quiz} uid={i.uid} scores={i.scores} /></td>
                                                {
                                                    i.score === "" ?
                                                        <td>TBD*</td> : <td>{i.score}</td>
                                                }
                                            </tr>
                                        )
                                    else if (this.state.search === "")
                                        return (
                                            <tr key={i.uid}>
                                                <td>{i.date}</td>
                                                <td>{i.name}</td>
                                                <td>{i.email}</td>
                                                <td><Drawing quiz={i.quiz} uid={i.uid} scores={i.scores} /></td>
                                                {
                                                    i.score === "" ?
                                                        <td>TBD*</td> : <td>{i.score}</td>
                                                }
                                            </tr>
                                        )
                                })}
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
            </div >
        )
    }
}

export default AdminView
