import React from "react";
import QuizCard from "./QuizCard";

const NewQuizBody = ({ uid }) => {
  return (
    <>
      <div className="newquizbody_container">
        <div className="quiz_cards">
          <QuizCard
            imgurl={"./images/sourcing_basics.png"}
            heading={"Sourcing Basics"}
            tag={"5 Marks"}
            time={"20 mins"}
            uid={uid}
            desc={`Check your Boolean fundamentals and check where you stand in the basic fundamentals. This test is applicable for anyone who is involved in tech sourcing and has worked on roles like backend, frontend, devops.
            `}
          />
          <QuizCard
            imgurl={"./images/tech_fundamentals.png"}
            heading={"Tech Fundamentals"}
            tag={"10 Marks"}
            time={"15 mins"}
            uid={uid}
            desc={`Test your basic tech fundamentals. This involves roles around digital tech stack frontend, backend, fullstack, devops.`}
          />
          <QuizCard
            imgurl={"./images/xray_basic.png"}
            heading={"Xray Basic Sourcing"}
            tag={"10 Marks"}
            time={"20 min"}
            uid={uid}
            desc={`This covers all the basic questions around Xray. The set of questions in this module will help you understand how strong are your Xray fundamentals.`}
          />
          <QuizCard
            imgurl={"./images/xray_advance.png"}
            heading={"Xray Advance Sourcing"}
            tag={"25 Marks"}
            time={"45 min"}
            uid={uid}
            desc={`Do you consider yourself a sourcing pro in Xray? Then these puzzles will be mind tickling & it will help you think outside the box while implementing the search structure 
            `}
          />
          <QuizCard
            imgurl={"./images/monk_india.png"}
            heading={"Monk Sourcing (India)"}
            tag={"50 Marks"}
            time={"60 min"}
            uid={uid}
            desc={`This is a peculiar sourcing test, you can crack it only if you have taken up any of the monk sourcing courses. The questions listed will measure how structured is your sourcing approach and do you have a strong understanding of implementing BigData concepts in sourcing.
            `}
          />
        </div>
      </div>
    </>
  );
};

export default NewQuizBody;
