import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


export default class T1 extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Card className="Tmon">
                <CardContent className="media" align="center">
                    <img src="/images/Arshad.jpeg" alt="Arshad" />
                </CardContent>
                <CardContent align="center">
                    Its amazing platform from RecruitingMonk, you will get a lot to learn / to sharpen your recruiting skill. I urge all my friends in Recruitment industry to take up the Quiz.
                </CardContent>
                <div className="names" align="center">
                    <h3>Arshad S.</h3>
                    <p>[24]7.ai</p>
                </div>
            </Card>
        );
    }
}