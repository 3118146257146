import React, { Component } from 'react';
import Button from "@material-ui/core/Button";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core'
import Swal from "sweetalert2";
import PeopleIcon from '@material-ui/icons/People';
import Chip from '@material-ui/core/Chip';
import fire from '../../config/fire'

export default class Card3 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            detail: {},
            fill: true,
            id: this.props.uid,
            len: ''
        }
    }

    componentDidMount() {

        if (this.state.id !== null) {
            fire
                .database()
                .ref("UI_Developer")
                .once('value', snapshot => {
                    if (snapshot.hasChild(this.props.uid))
                        this.setState({
                            fill: false
                        })
                });

            fire.database().ref("UI_Developer/" + this.props.uid).once('value', snapshot => {
                let list = snapshot.val()

                this.setState({ detail: list })
            })
        }

        fire.database().ref("UI_Developer").once('value', snapshot => {
            let l = Object.keys(snapshot.val()).length

            this.setState({ len: l })
        })
    }

    handleStart = () => {

        if (this.props.uid === null)
            Swal.fire({
                title: "Please Login First!!",
                icon: "error"
            });
        else
            window.location.href = '/ui-quiz';
    }

    render() {
        return (
            <Grid container className="card-div">
                <Card className="single-card">
                    <Grid item xs={12} md={5} style={{ alignSelf: 'center' }}>
                        <img
                            width="100%"
                            src="/images/ui.jpeg"
                            title="Recruiting Monk"
                        />
                    </Grid>
                    <Grid item xs={12} md={7} className="detail">
                        <CardContent className="title">
                            <h3>UI Developer</h3>
                            <div className="float"><h5>Duration: 10 mins</h5></div>
                        </CardContent>
                        <CardContent style={{ paddingBottom: '20px' }}>
                            <div className="chip-container">
                                <Chip className="chip" variant="outlined" label="Fundamentals" color="secondary" />
                                <Chip className="chip" variant="outlined" label="UI" color="secondary" />
                            </div>
                            <div className="float">
                                {
                                    this.state.fill === true ? <Button className="take-quiz" variant="outlined" size="small" onClick={this.handleStart} >Take Quiz</Button>
                                        :
                                        this.state.detail.score ? <span className="score">Score: {this.state.detail.score}/10</span> : <span className="score">Score awaited..</span>
                                }
                            </div>
                            <span className="taken"><span className="count">{this.state.len} </span> Recruiters <PeopleIcon fontSize="small" className="count-icon" style={{ paddingLeft: '5px' }} /></span>
                        </CardContent>
                    </Grid>
                </Card>
            </Grid>
        );
    }
}