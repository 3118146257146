import React, { useState, useEffect } from 'react';
import Button from "@material-ui/core/Button";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core'
import Swal from "sweetalert2";
import PeopleIcon from '@material-ui/icons/People';
import Chip from '@material-ui/core/Chip';
import fire from '../../config/fire';

function Card1(props) {
    const [detail, setDetail] = useState({});
    const [fill, setFill] = useState(true);
    const [len, setLen] = useState('');
    
    useEffect(() => {
        const fetchData = async () => {
            if (props.uid !== null) {
                const snapshot = await fire.database().ref("Boolean_basics").child(props.uid).once('value');
                if (snapshot.exists()) {
                    setFill(false);
                }

                const detailSnapshot = await fire.database().ref("Boolean_basics/" + props.uid).once('value');
                const list = detailSnapshot.val();
                setDetail(list);
            }

            const snapshot = await fire.database().ref("Boolean_basics").once('value');
            const l = Object.keys(snapshot.val()).length;
            setLen(l);
        };

        fetchData();
    }, [props.uid]);

    const handleStart = () => {
        if (props.uid === null) {
            Swal.fire({
                title: "Please Login First!!",
                icon: "error"
            });
        } else {
            window.location.href = '/boolean-basics-quiz';
        }
    }

    console.log(detail);

    return (
        <Grid container className="card-div">
            <Card className="single-card">
                <Grid item xs={12} md={5} style={{ alignSelf: 'center' }}>
                    <img
                        width="100%"
                        src="/images/boolean_basics.jpeg"
                        title="Recruiting Monk"
                    />
                </Grid>
                <Grid item xs={12} md={7} className="detail">
                    <CardContent className="title">
                        <h3>Boolean Basics</h3>
                        <div className="float"><h5>Duration: 10 mins</h5></div>
                    </CardContent>
                    <CardContent style={{ paddingBottom: '20px' }}>
                        <div className="chip-container">
                            <Chip className="chip" variant="outlined" label="Fundamentals" color="secondary" />
                            <Chip className="chip" variant="outlined" label="Boolean" color="secondary" />
                        </div>
                        <div className="float">
                            {
                                fill === true ? (
                                    <Button className="take-quiz" variant="outlined" size="small" onClick={handleStart} >Take Quiz</Button>
                                ) : (
                                    detail.score ? (
                                        <span className="score">Score: {detail.score}/10</span>
                                    ) : (
                                        <span className="score">Score awaited..</span>
                                    )
                                )
                            }
                        </div>
                        <span className="taken"><span className="count">{len} </span> Recruiters <PeopleIcon fontSize="small" className="count-icon" style={{ paddingLeft: '5px' }} /></span>
                    </CardContent>
                </Grid>
            </Card>
        </Grid>
    );
}

export default Card1;
