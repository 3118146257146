import React from 'react'
import fire from '../../config/fire'
import Swal from "sweetalert2";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import Contact from '../Contact'

function getSteps() {
    return ['Question 1', 'Question 2', 'Question 3', 'Question 4', 'Question 5'];
}

function UIQues(props) {

    const [state, setState] = React.useState({
        name: props.name,
        email: props.email,
        uid: props.uid,
        answer1: "",
        answer2: "",
        answer3: "",
        answer4: "",
        answer5: "",
        date: new Date().toLocaleString("en-IN"),
        score: "",
        scores: ["", "", "", "", ""],
        quiz: "UI_Developer"
    })

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleText = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Grid container>
                        <Typography>Write a Boolean search for the below UI developer Role. Ensure you write one search, that will give quantitative & qualitative data sets.</Typography>
                        <ul>
                            <li>Hands on experience as UI developer.</li>
                            <li>Experience in writing clean & reusable code using AngularJS.</li>
                            <li>Should have good hands on with html, bootstrap, CSS & JS.</li>
                            <li>Exposure to building cross browser applications.</li>
                            <li>Good understanding of Ajax & Jquery.</li>
                        </ul>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <TextField label="Enter your answer Here" variant="outlined" name="answer1"
                                onChange={handleText} value={state.answer1} fullWidth multiline rowsMax={5} />
                        </Grid>
                    </Grid>
                )
            case 1:
                return (
                    <Grid container>
                        <Typography>Which of the below search you would opt for?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer2" value={state.answer2} onChange={handleText}>
                                    <FormControlLabel value="(Angular OR AngularJS) AND HTML AND CSS" control={<Radio />} label="(Angular OR AngularJS) AND HTML AND CSS" />
                                    <FormControlLabel value='(Angular OR AngularJS OR “Angular js”) AND HTML AND CSS' control={<Radio />} label='(Angular OR AngularJS OR “Angular js”) AND HTML AND CSS' />
                                    <FormControlLabel value='(Angular OR AngularJS OR “Angular js” OR “Angular.js”) AND HTML AND CSS' control={<Radio />} label='(Angular OR AngularJS OR “Angular js” OR “Angular.js”) AND HTML AND CSS' />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 2:
                return (
                    <Grid container>
                        <Typography>Which of the below is wrong?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer3" value={state.answer3} onChange={handleText}>
                                    <FormControlLabel value="(reactnative OR reactredux)" control={<Radio />} label="(reactnative OR reactredux)" />
                                    <FormControlLabel value="(Phonegap OR Cordova)" control={<Radio />} label="(Phonegap OR Cordova)" />
                                    <FormControlLabel value="(Flutter OR ReactNative)" control={<Radio />} label="(Flutter OR ReactNative)" />
                                    <FormControlLabel value="(angular OR react)" control={<Radio />} label="(angular OR react)" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                    </Grid>
                )
            case 3:
                return (
                    <Grid container>
                        <Typography>If you’ve to hire for a MEAN stack developer, what will be your search string? Make sure, your search results don't yield Frontend developers. (Try to minimize unsuitable results).</Typography>
                        <ul>
                            <li>Experience with AngularJs, Nodejs.</li>
                            <li>Hands on with ExpressJS.</li>
                            <li>Good exposure to MySQL database.</li>
                        </ul>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <TextField label="Enter your answer Here" variant="outlined" name="answer4"
                                onChange={handleText} value={state.answer4} fullWidth multiline rowsMax={5} />
                        </Grid>
                    </Grid>
                )
            case 4:
                return (
                    <Grid container>
                        <Typography>Pick the odd one out from the below?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer5" value={state.answer5} onChange={handleText}>
                                    <FormControlLabel value="Angular" control={<Radio />} label="Angular" />
                                    <FormControlLabel value="Backbone" control={<Radio />} label="Backbone" />
                                    <FormControlLabel value="ReactNative" control={<Radio />} label="ReactNative" />
                                    <FormControlLabel value="Vue" control={<Radio />} label="Vue" />
                                    <FormControlLabel value="None of the above" control={<Radio />} label="None of the above" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
        }
    }

    const handleSubmit = () => {

        if (props.fill === true) {
            fire
                .database()
                .ref("UI_Developer")
                .child(props.uid)
                .set(state)

            Swal.fire({
                icon: "success",
                title: "Awesome! You've completed UI Developer Test",
                text: "You'll receive score within 24HRs"
            }).then(function () {
                window.location.href = '/share-experience'
            });
        }
        else {
            Swal.fire({
                icon: "error",
                title: "OOPS",
                text: "Quiz already attempted"
            }).then(function () {
                window.location.href = '/'
            });
        }
    };

    if (props.attempt)
        return (
            <div>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                <Typography>{getStepContent(index)}</Typography>
                                <div>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        >Back</Button>
                                        {activeStep === steps.length - 1 ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                            >Finish</Button>
                                            :
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                            >Next/Skip</Button>
                                        }
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                <Contact />
            </div>
        )
    else
        handleSubmit()
}

export default UIQues