import React, { useEffect, useState } from "react";
import fire from "../../config/fire";
import Swal from "sweetalert2";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Contact from "../Contact";
import { Checkbox } from "@material-ui/core";

function getSteps() {
  return [
    "Question 1",
    "Question 2",
    "Question 3",
    "Question 4",
    "Question 5",
    "Question 6",
    "Question 7",
    "Question 8",
    "Question 9",
    "Question 10",
  ];
}

function Sourcing_Basic_Ques(props) {

  const [enableBeforeUnload, setEnableBeforeUnload] = useState(true);
  const [state, setState] = React.useState({
    name: props.name,
    email: props.email,
    uid: props.uid,
    answer1: [
      {
        id: 1,
        value: "“Java” AND “Spring” AND “Hibernate” AND “Linux”",
        isChecked: false,
      },
      {
        id: 2,
        value: "(Java) AND (Spring AND hibernate) AND (Linux)",
        isChecked: false,
      },
      {
        id: 3,
        value: "Java AND Spring AND Hibernate AND Linux",
        isChecked: false,
      },
      {
        id: 4,
        value: "(Java) AND (Spring) AND (Hibernate) AND (Linux)",
        isChecked: false,
      },
    ],
    answer2: "",
    answer3: [
      { id: 1, value: "(Java AND Spring OR Struts)", isChecked: false },
      { id: 2, value: "Java AND Spring AND Struts", isChecked: false },
      { id: 3, value: "(Java AND Spring AND Struts)", isChecked: false },
    ],
    answer4: "",
    answer5: "",
    answer6: "",
    answer7: [
      {
        id: 1,
        value: "C# AND ASP.NET AND Webservices AND “SQL Server”",
        isChecked: false,
      },
      {
        id: 2,
        value: "C# AND ASP.NET AND Webservices AND SQL Server",
        isChecked: false,
      },
    ],
    answer8: [
      { id: 1, value: "Angular + HTML + Node + SQL", isChecked: false },
      { id: 2, value: "JavaScript + Node + SQL + AWS", isChecked: false },
      { id: 3, value: "React + CSS + Java + Azure", isChecked: false },
      { id: 4, value: "Vue + HTML + Python + SQL", isChecked: false },
    ],
    answer9: [
      {
        id: 1,
        value: "Java + Hibernate framework + Rest + SQL",
        isChecked: false,
      },
      {
        id: 2,
        value: "Core Java + SpringMVC + Webservice + MySQL",
        isChecked: false,
      },
      {
        id: 3,
        value: "Java + Entity Framework + Webservice + SQL",
        isChecked: false,
      },
      {
        id: 4,
        value: "Core Java + Spring framework + Rest + SQL",
        isChecked: false,
      },
    ],
    answer10: [
      { id: 1, value: "(GCP OR Azure)", isChecked: false },
      { id: 2, value: "(JavaScript OR Perl)", isChecked: false },
      { id: 3, value: "(Cloud OR Microservices)", isChecked: false },
      { id: 4, value: "(Webservices OR Microservices)", isChecked: false },
    ],
    date: new Date().toLocaleString("en-IN"),
    score: "",
    performance: "",
    scores: ["", "", "", "", "", "", "", "", "", ""],
    quiz: "Sourcing_basic",
  });

  
  useEffect(() => {
      const handleBeforeUnload = (e) => {
          if (enableBeforeUnload) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";

        // Add a console message when the user clicks "OK"
        window.addEventListener("unload", () => {
          fire
            .database()
            .ref("Sourcing_basic")
            .child(props.uid)
            .set(state);
        });
      }
    };

    if (enableBeforeUnload) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [enableBeforeUnload, state]);

  useEffect(() => {
    Swal.fire({
      icon: "warning",
      text: "If you exit the Quiz without completing it, your progress will be Submitted.",
    });
  }, []);

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleText = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange = (event, answerName) => {
    let updatedAnswers = [...state[answerName]]; // Copy the original answers array
    updatedAnswers.forEach((answer) => {
      if (answer.value === event.target.value) {
        answer.isChecked = event.target.checked;
      }
    });
    setState({ ...state, [answerName]: updatedAnswers }); // Update the state with the modified answers array
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container>
            <Typography>
              Out of the below searches which is/are technically (as per Boolean
              usage) correct?
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer1.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer1")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container>
            <Typography>Write a Boolean for the below skill set</Typography>
            <Grid item xs={12}>
              <ul>
                <li>Experience as Java developer</li>
                <li>Good exposure to hibernate, springMVC</li>
                <li>Experience with webservices .</li>
                <li>
                  Should have experience with both SQL & Redis or good with
                  MongoDB
                </li>
              </ul>
            </Grid>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer2"
                onChange={handleText}
                value={state.answer2}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container>
            <Typography>Which of the below is wrong ?</Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer3.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer3")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );
      case 3:
        return (
          <Grid container>
            <Typography>
              {" "}
              If you’ve to hire a fullstack Java developer, what will be your
              search string? Make sure you have to write one string, it has to
              get a quantitative & qualitative data set.
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer4"
                onChange={handleText}
                value={state.answer4}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );
      case 4:
        return (
          <Grid container>
            <Typography>
              Write a Boolean search for the below UI developer Role. Ensure you
              write one search, that will give quantitative & qualitative data
              sets.
            </Typography>
            <Grid item xs={12}>
              <ul>
                <li>Hands on experience as UI developer</li>
                <li>
                  Experience in writing clean & reusable code using AngularJS.
                </li>
                <li>
                  Should have good hands on with html, bootstrap, css & JS
                </li>
                <li>Exposure to building cross browser applications</li>
                <li>Good understanding of Ajax & Jquery.</li>
              </ul>
            </Grid>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer5"
                onChange={handleText}
                value={state.answer5}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );
      case 5:
        return (
          <Grid container>
            <Typography>
              Write a Boolean search for the below Devops Engineer Role. Ensure you
              write one search, that will give quantitative & qualitative data
              sets.
            </Typography>
            <Grid item xs={12}>
              <ul>
                <li>Hands on experience as Devops Engineer</li>
                <li>Experience in working with scalable applications.</li>
                <li>
                  Should have good hands on with scripting languages like
                  Python, JavaScript.
                </li>
                <li>Experience of working in microservices environment</li>
                <li>Exposure to any of the cloud platforms.</li>
                <li>Should have hands on experience with CI/CD technologies</li>
              </ul>
            </Grid>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer6"
                onChange={handleText}
                value={state.answer6}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );
      case 6:
        return (
          <Grid container>
            <Typography>
              If you had to pick one boolean from the below two, which one would
              you choose?
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer7.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer7")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );
      case 7:
        return (
          <Grid container>
            <Typography>
              Which of the below does not fit for Fullstack
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer8.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer8")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );
      case 8:
        return (
          <Grid container>
            <Typography>Pick odd one out</Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer9.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer9")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );
      case 9:
        return (
          <Grid container>
            <Typography>
              Which of the below is wrong?
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer10.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer10")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );
    }
  };

  const handleSubmit = () => {
    setEnableBeforeUnload(false)
    if (props.fill === true) {
      fire.database().ref("Sourcing_basic").child(props.uid).set(state);

      Swal.fire({
        icon: "success",
        title: "Awesome! You've completed Sourcing Basic Quiz",
        text: "You'll receive score within 24HRs",
      }).then(function () {
        window.location.href = "/share-experience";
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "OOPS",
        text: "Quiz already attempted",
      }).then(function () {
        window.location.href = "/";
      });
    }
  };

  if (props.attempt)
    return (
      <div>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(index)}</Typography>
                <div>
                  <div>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Back
                    </Button>
                    {activeStep === steps.length - 1 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                      >
                        Finish
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        Next/Skip
                      </Button>
                    )}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>

        <Contact />
      </div>
    );
  else handleSubmit();
}

export default Sourcing_Basic_Ques;
