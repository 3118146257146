import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyCAUK7c-KNMIsYHV9tr9cUFtF7w68s362w",
    authDomain: "quiz.recruitingmonk.com",
    databaseURL: "https://ta-coaching.firebaseio.com",
    projectId: "ta-coaching",
    storageBucket: "ta-coaching.appspot.com",
    messagingSenderId: "503126540750",
    appId: "1:503126540750:web:cacc82d93b69435aec2b9f",
    measurementId: "G-EBB92T4QEN"
};
// Initialize Firebase
const fire = firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
    auth.signInWithPopup(provider);
};

const prov = new firebase.auth.FacebookAuthProvider();
export const signInWithFb = () => {
    auth.signInWithPopup(prov);
};

export default fire;