import React, { Component } from 'react';
import Countdown from 'react-countdown';
import RecroQues from './RecroQues'
import Grid from '@material-ui/core/Grid';
import fire from '../../config/fire'

class RecroQuiz extends Component {

    constructor(props) {
        super(props)

        this.state = {
            fill: true,
            attempt: 1
        }
    }

    handleText = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    };

    handleAttempt = () => {
        this.setState({
            attempt: 0
        });
    };

    componentDidMount() {
        fire
            .database()
            .ref("recro")
            .once('value', snapshot => {
                if (snapshot.hasChild(this.props.uid))
                    this.setState({
                        fill: false
                    })
            });
    }

    renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            this.handleAttempt()
            return <h2>Time's Up!!</h2>
        } else {
            // Render a countdown
            return (
                <Grid item xs={12} className="clock">
                    <h1>{minutes}:{seconds}</h1>
                </Grid>
            )
        }
    };

    render() {

        return (
            <div>
                {
                    this.state.fill ?
                        <Grid container className="quiz">
                            <Grid item xs={12} md={7} align="right">
                                <p style={{ fontSize: '18px', paddingLeft: '10px' }}>Recruiter Quiz <span style={{ fontWeight: '500' }}>Powered by</span> <a href="https://recruitingmonk.com/" target="_blank">RecruitingMonk</a></p>
                                <p style={{ fontSize: '15px', paddingLeft: '10px' }}>Recruiter Hiring - Recro</p>
                            </Grid>
                            <Grid item xs={12} md={5} align="right">
                                <Countdown
                                    align="right"
                                    date={Date.now() + 600000}
                                    renderer={this.renderer}
                                /></Grid>
                            <Grid container>
                                <Grid item xs={12} md={7} className="questions">
                                    <RecroQues uid={this.props.uid} fill={this.state.fill} attempt={this.state.attempt} name={this.props.name} email={this.props.email} />
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        <Grid container>
                            <Grid item xs={12} style={{ padding: '10px' }}>
                                <p>Quiz Already Attempted</p>
                            </Grid>
                        </Grid>
                }
            </div>
        );
    }
}

export default RecroQuiz;