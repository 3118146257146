import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import fire from "../../config/fire";
import { useParams } from "react-router-dom";

const ProfileScoreCard = ({ heading, score, uid }) => {
  const [detail, setDetail] = useState({});
  const [testTaken, setTestTaken] = useState(true);
  const [navigateQuiz, setNavigateQuiz] = useState("");
  const { id } = useParams();

  useEffect(() => {
    const fetchDataSourcing = async () => {
      if (id !== null) {
        const snapshot = await fire
          .database()
          .ref("Sourcing_basic")
          .child(id)
          .once("value");
        if (snapshot.exists()) {
          const detailSnapshot = await fire
            .database()
            .ref("Sourcing_basic/" + id)
            .once("value");
          const list = detailSnapshot.val();
          setDetail(list);
        } else {
          setTestTaken(false);
          setNavigateQuiz("sourcing-basic-quiz");
        }
      }
    };

    const fetchDataTech = async () => {
      if (id !== null) {
        const snapshot = await fire
          .database()
          .ref("Tech_fundamentals")
          .child(id)
          .once("value");
        if (snapshot.exists()) {
          const detailSnapshot = await fire
            .database()
            .ref("Tech_fundamentals/" + id)
            .once("value");
          const list = detailSnapshot.val();
          setDetail(list);
        } else {
          setTestTaken(false);
          setNavigateQuiz("tech-fundamentals-quiz");
        }
      }
    };

    const fetchDataXray = async () => {
      if (id !== null) {
        const snapshot = await fire
          .database()
          .ref("Xray_Sourcing_Basic")
          .child(id)
          .once("value");
        if (snapshot.exists()) {
          const detailSnapshot = await fire
            .database()
            .ref("Xray_Sourcing_Basic/" + id)
            .once("value");
          const list = detailSnapshot.val();
          setDetail(list);
        } else {
          setTestTaken(false);
          setNavigateQuiz("xray-basic-sourcing-quiz");
        }
      }
    };

    const fetchDataXrayAdvance = async () => {
      if (id !== null) {
        const snapshot = await fire
          .database()
          .ref("Xray_Sourcing_Advance")
          .child(id)
          .once("value");
        if (snapshot.exists()) {
          const detailSnapshot = await fire
            .database()
            .ref("Xray_Sourcing_Advance/" + id)
            .once("value");
          const list = detailSnapshot.val();
          setDetail(list);
        } else {
          setTestTaken(false);
          setNavigateQuiz("xray-advance-sourcing-quiz");
        }
      }
    };


    const fetchDataMonk = async () => {
      if (id !== null) {
        const snapshot = await fire
          .database()
          .ref("Monk_Sourcing_India")
          .child(id)
          .once("value");
        if (snapshot.exists()) {
          const detailSnapshot = await fire
            .database()
            .ref("Monk_Sourcing_India/" + id)
            .once("value");
          const list = detailSnapshot.val();
          setDetail(list);
        } else {
          setTestTaken(false);
          setNavigateQuiz("monk-sourcing-india");
        }
      }
    };


    if (heading === "Sourcing Basic") {
      fetchDataSourcing();
    }
    if (heading === "Tech Fundamentals") {
      fetchDataTech();
    }
    if (heading === "Xray Basic Sourcing") {
      fetchDataXray();
    }
    if (heading === "Xray Advance Sourcing") {
      fetchDataXrayAdvance();
    }
    if (heading === "Monk Sourcing (India)") {
      fetchDataMonk();
    }
  }, []);
  

  return (
    <div
      className={
        (!testTaken && id === uid )? "scorecard_data1" : `scorecard_data`
      }
    >
      <div className="scorecard_content">
        {!testTaken
          ? "Not Attempted"
          : (detail.score === "" || detail.score === undefined )
          ? "Score Awaited"
          : `${detail.score} / ${score}`}
      </div>
      <div className="scorecard_heading">{heading}</div>
      <div className="attempt_quiz">
        <button
          className="take_quiz"
          onClick={() => (window.location.href = `/${navigateQuiz}`)}
        >
          Take Quiz
        </button>
      </div>
    </div>
  );
};

export default ProfileScoreCard;
