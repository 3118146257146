import React, { Component } from 'react';
import Drawer from '@material-ui/core/Drawer';
import { Button, Grid } from '@material-ui/core';
import fire from '../config/fire'

class Drawing extends Component {

    constructor(props) {
        super(props)

        this.state = {
            top: false,
            detail: [],
            ques: [],
            category: [],
            scores: this.props.scores
        }
    }

    componentDidMount() {
        fire.database().ref(this.props.quiz + "/" + this.props.uid).once('value', snapshot => {
            let list = []

            snapshot.forEach(snap => {
                list.push(snap.val());
            })

            this.setState({ detail: list })
        })

        fire.database().ref("Questions/" + this.props.quiz).once('value', snapshot => {
            let l = []
            let qn = []
            let cat = []

            snapshot.forEach(snap => {
                l.push(snap.val());
            })

            for (var i = l.length / 2; i < l.length; i++)
                qn.push(l[i])

            for (var i = 0; i < l.length / 2; i++)
                cat.push(l[i])

            this.setState({ ques: qn, category: cat })
        })
    }

    toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        this.setState({ [anchor]: open });
    };

    handleScore = () => {
        let s = []

        for (var i = 0; i < this.props.scores.length; i++) {
            var id = "score" + i
            console.log(id)
            s.push(document.getElementById(id).value)
        }

        let sum = 0
        for (var i = 0; i < s.length; i++)
            sum = sum + parseFloat(s[i])

        sum = sum.toString()
        fire.database().ref(this.props.quiz)
            .child(this.props.uid).update({
                'score': sum,
                'scores': s
            })

        this.setState({ scores: s })
        this.componentDidMount()
    }

    handleEdit = () => {
        let n = []

        for (var i = 0; i < this.props.scores.length; i++)
            n.push("")

        console.log(n)
        fire.database().ref(this.props.quiz)
            .child(this.props.uid).update({
                'score': "",
                'scores': n
            })

        this.setState({ scores: n })
        this.componentDidMount()
    }

    list = (anchor) => (
        <div
            className="drawer"
            role="presentation"
        >
            <Grid container>
                <Grid item xs={12}>
                    <h3>{this.props.quiz}</h3>
                    <div>
                        <ol>
                            {
                                this.state.ques.map((data, index) => {
                                    return (
                                        <div>
                                            <li>{data} (<b>Category: {this.state.category[index]}</b>)</li>
                                            {
                                                Array.isArray(this.state.detail[index]) ?
                                                    <div>
                                                        {
                                                            this.state.detail[index].map(data => {
                                                                if (data.isChecked === true)
                                                                    return (
                                                                        <p><b>Ans.</b> {data.value}</p>
                                                                    )
                                                            })
                                                        }
                                                        <div style={{ padding: '5px', marginBottom: '10px' }}>
                                                            {
                                                                this.state.scores[index] === "" ?
                                                                    <span>TBD*</span> : <span>Score: {this.state.scores[index]}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    <div><span><b>Ans.</b> </span>{this.state.detail[index]}
                                                        <div style={{ padding: '5px', marginBottom: '10px' }}>
                                                            {
                                                                this.state.scores[index] === "" ?
                                                                    <span>TBD*</span> : <span>Score: {this.state.scores[index]}</span>
                                                            }
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                            <br />
                            {
                                this.state.scores[0] === "" ?
                                    <p>TBD*</p>
                                    :
                                    <p>Total: {this.state.detail[this.state.detail.length - 3]}</p>
                            }
                        </ol>
                    </div>
                </Grid>
            </Grid>
        </div>
    );

    render() {
        return (
            <div>
                <Button variant="outlined" color="primary" onClick={this.toggleDrawer('top', true)}>Check</Button>
                <Drawer anchor="top" open={this.state.top} onClose={this.toggleDrawer('top', false)}>
                    {this.list('top')}
                </Drawer>
            </div>
        );
    }
}

export default Drawing
