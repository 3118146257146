import React from "react";
import fire from "../../config/fire";
import Swal from "sweetalert2";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Contact from "../Contact";

function getSteps() {
  return ["Question 1", "Question 2", "Question 3", "Question 4", "Question 5"];
}

function CompetitionQues(props) {
  const [state, setState] = React.useState({
    name: props.name,
    email: props.email,
    uid: props.uid,
    answer1: "",
    answer2: "",
    answer3: "",
    answer4: "",
    answer5: "",
    date: new Date().toLocaleString("en-IN"),
    score: "",
    performance: "",
    scores: ["", "", "", "", ""],
    quiz: "Competition",
  });

  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleText = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container>
            <Typography>What is state in React js?</Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer1"
                onChange={handleText}
                value={state.answer1}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container>
            <Typography>
              Which of the following is used in React.js to increase
              performance?
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                <RadioGroup
                  name="answer2"
                  value={state.answer2}
                  onChange={handleText}
                >
                  <FormControlLabel
                    value="Virtual DOM"
                    control={<Radio />}
                    label="Virtual DOM"
                  />
                  <FormControlLabel
                    value="Original DOM"
                    control={<Radio />}
                    label="Original DOM"
                  />
                  <FormControlLabel
                    value="Both A and B"
                    control={<Radio />}
                    label="Both A and B"
                  />
                  <FormControlLabel
                    value="None of the Above"
                    control={<Radio />}
                    label="None of the Above"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container>
            <Typography>What is JSX?</Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer3"
                onChange={handleText}
                value={state.answer3}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );
      case 3:
        return (
          <Grid container>
            <Typography>How to create a component in react?</Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer4"
                onChange={handleText}
                value={state.answer4}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );
      case 4:
        return (
          <Grid container>
            <Typography>
              Identify the one which is used to pass data to components from
              outside
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                <RadioGroup
                  name="answer5"
                  value={state.answer5}
                  onChange={handleText}
                >
                  <FormControlLabel
                    value="Render With Arguments"
                    control={<Radio />}
                    label="Render With Arguments"
                  />
                  <FormControlLabel
                    value="setState"
                    control={<Radio />}
                    label="setState"
                  />
                  <FormControlLabel
                    value="PropTypes"
                    control={<Radio />}
                    label="PropTypes"
                  />
                  <FormControlLabel
                    value="props"
                    control={<Radio />}
                    label="props"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        );
    }
  };

  const handleSubmit = () => {
    if (props.fill === true) {
      fire.database().ref("Competition").child(props.uid).set(state);

      Swal.fire({
        icon: "success",
        title: "Awesome! You've completed Competition - I Test",
        text: "You'll receive score within 24HRs",
      }).then(function () {
        window.location.href = "/share-experience";
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "OOPS",
        text: "Quiz already attempted",
      }).then(function () {
        window.location.href = "/";
      });
    }
  };

  if (props.attempt)
    return (
      <div>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(index)}</Typography>
                <div>
                  <div>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Back
                    </Button>
                    {activeStep === steps.length - 1 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                      >
                        Finish
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        Next/Skip
                      </Button>
                    )}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>

        <Contact />
      </div>
    );
  else handleSubmit();
}

export default CompetitionQues;
