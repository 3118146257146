import React from "react";
import Home from './Components/Home'
import Loader from './Loader'
import Leaderboard from './Rank/Leaderboard'
import Blog from './Components/Blog/Blog'
import Testimonial from './Components/Testimonials/Testimonial'

function Main(props) {

  return (
    <div>
      <Loader />
      <Home uid={props.uid} />
      <Leaderboard />
      <Blog />
      <Testimonial />
    </div>
  );
}
export default Main