import React from 'react'
import fire from '../../config/fire'
import Swal from "sweetalert2";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import Contact from '../Contact'

function getSteps() {
    return ['Question 1', 'Question 2', 'Question 3', 'Question 4', 'Question 5', 'Question 6'];
}

function TestQues(props) {

    const [state, setState] = React.useState({
        name: props.name,
        email: props.email,
        uid: props.uid,
        answer1: "",
        answer2: "",
        answer3: "",
        answer4: "",
        answer5: "",
        answer6: "",
        date: new Date().toLocaleString("en-IN"),
        score: "",
        scores: ["", "", "", "", "", ""],
        quiz: "batch"
    })

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleText = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Grid container>
                        <Typography>Write a Subject & mail for the below case:</Typography>
                        <Typography>You're applying for a Job & the company gets too many applications. Your subject line has to be intriguing for the HR to open & write a mail upto 100 word count, ensure the mail has to speak about why you deserve the job ?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <TextField label="Enter your answer Here" variant="outlined" name="answer1"
                                onChange={handleText} value={state.answer1} fullWidth multiline rows={10} rowsMax={20} />
                        </Grid>
                    </Grid>
                )
            case 1:
                return (
                    <Grid container>
                        <Typography>What according to you takes priority? (Place the below in your priority order)</Typography>
                        <ol>
                            <li>Role</li>
                            <li>Money</li>
                            <li>Acknowledgement </li>
                            <li>Scope to learn more </li>
                        </ol>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <TextField label="Enter your order here (comma separated)" variant="outlined" name="answer2"
                                onChange={handleText} value={state.answer2} fullWidth />
                        </Grid>
                    </Grid>
                )
            case 2:
                return (
                    <Grid container>
                        <Typography>Which of the given shapes would complete the sequence?</Typography>
                        <img src="/images/ques.png" />
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer3" value={state.answer3} onChange={handleText}>
                                    <FormControlLabel value="A" control={<Radio />} label="A" />
                                    <FormControlLabel value="B" control={<Radio />} label="B" />
                                    <FormControlLabel value="C" control={<Radio />} label="C" />
                                    <FormControlLabel value="D" control={<Radio />} label="D" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 3:
                return (
                    <Grid container>
                        <Typography>College principal has received complaints about bullying . He wants to investigate and end this situation as soon as possible, so he has asked the teachers to watch closely. Which situation should the teachers report to the principal?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer4" value={state.answer4} onChange={handleText}>
                                    <FormControlLabel value="A girl is sitting grumpy on a bench reading a book and not interacting with her peers" control={<Radio />} label="A girl is sitting grumpy on a bench reading a book and not interacting with her peers" />
                                    <FormControlLabel value="Four girls are surrounding another girl and seem to have possession of her backpack" control={<Radio />} label="Four girls are surrounding another girl and seem to have possession of her backpack" />
                                    <FormControlLabel value="Two boys are playing a one-on-one game of basketball and are arguing over the last basket scored" control={<Radio />} label="Two boys are playing a one-on-one game of basketball and are arguing over the last basket scored" />
                                    <FormControlLabel value="Three boys are huddled over a handheld video game, which isn't supposed to be on school grounds" control={<Radio />} label="Three boys are huddled over a handheld video game, which isn't supposed to be on school grounds" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 4:
                return (
                    <Grid container>
                        <Typography>In a family, there are husband-wife, two sons and two daughters. All the ladies were invited to a dinner. Both sons went out to play. Husband did not return from office. Who was at home?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer5" value={state.answer5} onChange={handleText}>
                                    <FormControlLabel value="Only wife was at home" control={<Radio />} label="Only wife was at home" />
                                    <FormControlLabel value="Nobody was at home" control={<Radio />} label="Nobody was at home" />
                                    <FormControlLabel value="Only sons were at home" control={<Radio />} label="Only sons were at home" />
                                    <FormControlLabel value="All ladies were at home" control={<Radio />} label="All ladies were at home" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 5:
                return (
                    <Grid container>
                        <Typography>Today there's a rise in fake news across social media. People who want to instigate communal hatred use these channels & also sometimes political parties use to push their agendas via fake campaigns.</Typography>
                        <Typography>Whats the most recent fake news you came across? How do you think we should deal with this issue of fake news? (Write in 100 words)</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <TextField label="Enter your answer here" variant="outlined" name="answer6"
                                onChange={handleText} value={state.answer6} fullWidth multiline rows={10} rowsMax={25} />
                        </Grid>
                    </Grid>
                )
        }
    }

    const handleSubmit = () => {

        if (props.fill === true) {
            fire
                .database()
                .ref("batch")
                .child(props.uid)
                .set(state)

            Swal.fire({
                icon: "success",
                title: "Awesome! You've completed Test",
                text: "Thank you!"
            }).then(function () {
                window.location.href = '/share-experience'
            });
        }
        else {
            Swal.fire({
                icon: "error",
                title: "OOPS",
                text: "Test already attempted"
            }).then(function () {
                window.location.href = '/'
            });
        }
    };

    if (props.attempt)
        return (
            <div>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                <Typography>{getStepContent(index)}</Typography>
                                <div>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        >Back</Button>
                                        {activeStep === steps.length - 1 ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                            >Finish</Button>
                                            :
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                            >Next/Skip</Button>
                                        }
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                <Contact />
            </div>
        )
    else
        handleSubmit()
}

export default TestQues