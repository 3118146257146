import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import fire from "../config/fire";
import CanvasJSReact from '../assets/canvasjs.react'
import CircularProgress from '@material-ui/core/CircularProgress';
import { InputLabel, NativeSelect } from '@material-ui/core';
import moment from 'moment'

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default class Graphing extends Component {

    constructor(props) {
        super(props)

        this.state = {
            quiz: this.props.quiz,
            a1: '',
            a2: '',
            a3: '',
            a4: '',
            a5: '',
            date: 'All Time'
        }
    }

    componentDidMount() {
        if (this.state.quiz)
            fire.database().ref(this.state.quiz).once('value', snapshot => {
                let list = []

                snapshot.forEach(snap => {
                    if (snap.val().score != "")
                        list.push(snap.val());
                })

                this.setState({
                    a1: list.filter(o => o.score <= 2).length,
                    a2: list.filter(o => o.score > 2 && o.score <= 4).length,
                    a3: list.filter(o => o.score > 4 && o.score <= 6).length,
                    a4: list.filter(o => o.score > 6 && o.score <= 8).length,
                    a5: list.filter(o => o.score > 8).length
                })
            })
    }

    options = () => {

        let data = {
            animationEnabled: true,
            exportEnabled: true,
            theme: "light1",
            title: {
                text: "Quiz Data"
            },
            toolTip: {
                enabled: false
            },
            data: [{
                type: "pie",
                indexLabel: "{label} = {y} ",
                startAngle: 0,
                showInLegend: "true",
                legendText: "{label}",
                indexLabelFontSize: 18,
                dataPoints: [
                    { y: this.state.a1, label: "Score 0-2" },
                    { y: this.state.a2, label: "Score 2.1-4" },
                    { y: this.state.a3, label: "Score 4.1-6" },
                    { y: this.state.a4, label: "Score 6.1-8" },
                    { y: this.state.a5, label: "Score 8.1-10" }
                ]
            }]
        }

        return data
    }

    render() {
        if (this.state.a1 > 0)
            return (
                <div>
                    <Grid container><Grid item xs={12}><p></p></Grid></Grid>
                    <Grid container><Grid item xs={12}><p></p></Grid></Grid>
                    <CanvasJSChart options={this.options()} />
                </div>
            )
        else
            return (
                <div align="center" style={{ marginTop: '60px' }}>
                    <CircularProgress thickness={5} />
                </div>
            )
    }
}