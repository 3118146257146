import React, { Component } from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";
import Grid from '@material-ui/core/Grid';
import fire from "../config/fire"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class BlogForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            title: "",
            image: "",
            content: "",
            date: new Date().toDateString(),
            category: ""
        };

        this.handleChange = this.handleChange.bind(this)
    }

    handleText = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleChange(value) {
        this.setState({ content: value })
    }

    handleSubmit = () => {

        fire
            .database()
            .ref("Blog")
            .push(this.state);

        Swal.fire({
            title: "Blog Created!",
            icon: "success"
        });

        this.setState({
            title: "",
            content: "",
            image: "",
            category: ""
        });
    };

    modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
    }

    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]

    render() {

        return (
            <Grid container justify="center">
                <Grid item xs={12} style={{ margin: '20px' }}>
                    <TextField
                        onChange={this.handleText}
                        label="Title"
                        placeholder="Title"
                        fullWidth
                        name="title"
                        variant="outlined"
                        value={this.state.title}
                    />
                </Grid>
                <Grid item xs={12} style={{ margin: '20px' }}>
                    <TextField
                        onChange={this.handleText}
                        label="Image Url"
                        placeholder="Image Url"
                        fullWidth
                        name="image"
                        variant="outlined"
                        value={this.state.image}
                    />
                </Grid>
                <Grid item xs={12} style={{ margin: '20px' }}>
                    <TextField
                        onChange={this.handleText}
                        label="Category"
                        placeholder="Category"
                        fullWidth
                        name="category"
                        variant="outlined"
                        value={this.state.category}
                    />
                </Grid>
                <Grid item xs={12} style={{ margin: '20px' }}>
                    <ReactQuill value={this.state.content} modules={this.modules}
                        formats={this.formats}
                        onChange={this.handleChange} />
                </Grid>
                <Grid item xs={12} style={{ margin: '20px' }}>
                    <Button
                        className="submit"
                        variant="contained"
                        color="secondary"
                        type="submit"
                        onClick={this.handleSubmit}
                    >
                        Add
            </Button>
                </Grid>
            </Grid>
        );
    }
}

export default BlogForm;