import React, { Component } from 'react';
import { Button, Grid } from '@material-ui/core';
import { signInWithGoogle, signInWithFb } from './config/fire'
import FacebookIcon from '@material-ui/icons/Facebook';

class Proceed extends Component {

    constructor(props) {
        super(props)

    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    };

    handleOpen = () => {
        this.setState({
            open: true
        })
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    render() {
        return (
            <div align="center center">
                <Grid container aria-labelledby="login-popup" fullWidth>
                    <Grid item style={{ width: '100%' }}>
                        <Grid container className="login">
                            <Grid item xs={12}>
                                <p className="head">Login to attempt <span><h3>Monk Academy- Scholarship Test</h3></span></p>
                            </Grid>
                            <Grid item xs={12}>
                                <p></p>
                            </Grid>
                            <Grid item xs={12}>
                                <Button class="googleBtn" onClick={signInWithGoogle}>
                                    <img
                                        src="https://img.pngio.com/google-logo-png-circle-google-icon-png-white-transparent-google-logo-white-png-654_665.png"
                                        alt="logo"
                                    />Login With Google</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <p></p>
                            </Grid>
                            <Grid item xs={12}>
                                <Button class="fbBtn" onClick={signInWithFb}>
                                    <FacebookIcon /> Login With Facebook</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default Proceed;