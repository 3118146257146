import { Grid } from '@material-ui/core';
import React from 'react'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";
import { FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from "react-share";

export default function Share() {
    return (
        <Grid container className="login">
            <Grid item xs={12}>
                <h4>Awesome! You'll receive score within 24HRs</h4>
                <p>Let your network know & tickle their TA minds.</p>
                <p>Sharing is caring, spread the word.</p>
            </Grid>
            <Grid item xs={12}>
                <FacebookShareButton quote="Hey Recruiters, check this souricng test ?" url="https://quiz.recruitingmonk.com/" >
                    <FacebookIcon size={48} round={true} />
                </FacebookShareButton>
                <span> </span>
                <LinkedinShareButton title="Hey Recruiters, check this souricng test ?" url="https://quiz.recruitingmonk.com/" >
                    <LinkedinIcon size={48} round={true} />
                </LinkedinShareButton>
                <span> </span>
                <TwitterShareButton title="Hey Recruiters, check this souricng test ?" url="https://quiz.recruitingmonk.com/">
                    <TwitterIcon size={48} round={true} />
                </TwitterShareButton>
                <span> </span>
                <WhatsappShareButton title="Hey Recruiters, check this souricng test ?" url="https://quiz.recruitingmonk.com/">
                    <WhatsappIcon size={48} round={true} />
                </WhatsappShareButton>
            </Grid>
        </Grid>
    )
} 