import React from "react";

const content = [
  "Validate Your Skills as a Tech Recruiter",
  "A Chance to Join the Top 1% of Tech Recruiters",
  "Identify and Bridge Any Gaps",
  "Share Your Unique Scorecard on Social Media and Enhance Your Resume",
  "See Where You Rank Among Thousands of Recruiters",
];

const Info = () => {
  return (
    <div className="inforContainer">
      <div className="infotContainer_heading">
        Take Our Yearly Tech Recruiter Assessment and...
      </div>
      <div className="inforContainer_content">
          {content.map((content, key) => (
            <div className="infocontent" key={key}>
              <div className="tick">
                <img src="./images/tick.svg" alt="" />
              </div>
              <div className="content_heading">
              {content}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Info;
