import React, { Component, useContext } from "react";
import { UserContext } from "../Providers/UserProvider";
import { auth } from "../config/fire";
import fire from "../config/fire";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import ListItemText from "@material-ui/core/ListItemText";
import { Grid, Avatar, Button } from "@material-ui/core";
import Login from "../Login";
import HomeIcon from "@material-ui/icons/Home";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import RateReviewIcon from "@material-ui/icons/RateReview";
import InfoGather from "./InfoGather";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

function Mobile({ uid }) {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <span className="mob-menu">
      <IconButton
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        className="icons"
      >
        <MenuIcon />
      </IconButton>

      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        id="draw"
      >
        <React.Fragment>
          <IconButton className="mob-icon" href="/" onClick={handleDrawerClose}>
            <HomeIcon className="icons" />
            <ListItemText primary="Home" />
          </IconButton>

          {/* Leader Board option hidden as of now */}
          {/* <IconButton
            href="/#leaderboard"
            className="mob-icon"
            onClick={handleDrawerClose}
          >
            <AssignmentIndIcon className="icons" />
            <ListItemText primary="Leaderboard" />
          </IconButton> */}

          {/* <IconButton href="/#blog" className="mob-icon" onClick={handleDrawerClose}>
                        <LocalLibraryIcon className="icons" />
                        <ListItemText primary='Blog' />
                    </IconButton> */}
          <IconButton
            href={`/scorecard/${uid}`}
            className="mob-icon"
            onClick={handleDrawerClose}
          >
            <LocalLibraryIcon className="icons" />
            <ListItemText primary="My Dashboard" />
          </IconButton>

          {/* <IconButton
            href="/#testimonials"
            className="mob-icon"
            onClick={handleDrawerClose}
          >
            <RateReviewIcon className="icons" />
            <ListItemText primary="Testimonials" />
          </IconButton> */}
        </React.Fragment>
      </SwipeableDrawer>
    </span>
  );
}

class Ad extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fill: false,
    };
  }

  componentDidMount() {
    fire
      .database()
      .ref("CompanyDash")
      .once("value", (snapshot) => {
        if (snapshot.hasChild(this.props.uid))
          this.setState({
            fill: true,
          });
      });
  }

  render() {
    if (this.state.fill === true)
      return (
        <div style={{ padding: "15px", marginBottom: "50px" }}>
          <Button
            style={{ float: "right" }}
            variant="contained"
            className="button-default"
            href="/company-admin"
            startIcon={<ExitToAppIcon />}
          >
            Admin portal
          </Button>
        </div>
      );
    else return <div></div>;
  }
}

const Header = () => {
  const user = useContext(UserContext);

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {user ? (
        <div>
          <Grid container style={{ boxShadow: "0px 2px 4px grey", padding: "5px"}}>
            <Grid item xs={12}>
              <InfoGather uid={user.uid} photoURL={user.photoURL} name={user.displayName}/>
              <Grid
                container
                style={{ display: "inline-flex", backgroundColor: "#fff" }}
              >
                <Grid
                  item
                  xs={6}
                  md={8}
                  style={{ display: "flex", alignSelf: "center" }}
                >
                  <Mobile uid={user.uid} />
                  {/* <h3 className="name" style={{ float: 'left', color: '#b32800' }}>Welcome {user.displayName.substr(0, user.displayName.indexOf(' '))}</h3> */}
                  {screenWidth > 576 && (
                    <img src="/images/logo.png" className="logo" />
                  )}

                  <span className="subHead" style={{ alignSelf: "center",  marginLeft:"30px" }}>
                    <Button className="btn" href="/">
                      Home
                    </Button>
                    {/* Removing the leaderboard as of now */}
                    {/* <Button className="btn" href="/#leaderboard">Leaderboard</Button> */}

                    {/* Adding the USer dahboard option for user */}

                    <Button className="btn" href={`/scorecard/${user.uid}`}>
                      My Dashboard
                    </Button>

                    { (user.uid === "RTaq0vnUAmVzpsXJ4QqhK89g1Yk1" || user.uid === "JNnMXlsU5vTLR8dSUVPZT88OQM82" || user.uid === "l4NQtyTfI9PGGf3QvuaFbyO0frB2" || user.uid === "3emTXgEkN1RmlQzYGvLZcHtrBVr1" || user.uid === "EFIgL9gWeRhWcwoGLrgNbpottR92" || user.uid === "mxRpITJTqxgb0EJi5HFKWQSJjTe2") &&
                      <Button className="btn" href={`/admin`}>
                        Admin Portal
                      </Button>
                    }

                    {
                      //<Button className="btn" href="/#blog">Blog</Button>
                    }
                    {/* <Button className="btn" href="/">
                      Leaderboard
                    </Button> */}
                  </span>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                  className="logout"
                  style={{ alignSelf: "center" }}
                >
                  <div style={{ float: "right", display: "inline-flex" }}>
                    <Avatar
                      style={{ marginRight: "15px" }}
                      alt={user.displayName}
                      src={
                        user.photoURL ||
                        "https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png"
                      }
                    />
                    <Button
                      onClick={() => {
                        auth.signOut();
                        window.location.href = "/";
                      }}
                      className="button-default"
                      variant="contained"
                      color="primary"
                    >
                      <ExitToAppIcon />
                      Logout
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {(user.uid === "RTaq0vnUAmVzpsXJ4QqhK89g1Yk1" || user.uid === "JNnMXlsU5vTLR8dSUVPZT88OQM82" || user.uid === "l4NQtyTfI9PGGf3QvuaFbyO0frB2" || user.uid === "3emTXgEkN1RmlQzYGvLZcHtrBVr1" || user.uid === "EFIgL9gWeRhWcwoGLrgNbpottR92") ? (
                <div style={{ padding: "15px", marginBottom: "50px" }}>
                  <Button
                    style={{ float: "right" }}
                    variant="contained"
                    className="button-default"
                    href="/admin"
                    startIcon={<ExitToAppIcon />}
                  >
                    Go to admin portal
                  </Button>
                </div>
              ) : (
                <div></div>
              )}
              <Ad uid={user.uid} />
            </Grid>
          </Grid> */}
        </div>
      ) : (
        <Grid
          container
          style={{
            padding: "5px", 
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
          }}
        >
          <Grid item xs={12}>
            <Grid container style={{ display: "inline-flex" }}>
              <Grid item xs={8} id="mob" style={{ display: "flex" }}>
                <Mobile />
                <img src="/images/logo.png" className="logo" />
                <span className="subHead" style={{ alignSelf: "center", marginLeft:"30px" }}>

                  <Button
                    className="btn"
                    onClick={() => (window.location.href = "/")}
                  >
                    Home
                  </Button>
                  {/* <Button className="btn" href="/#leaderboard">Leaderboard</Button> */}
                  {
                    //<Button className="btn" href="/#blog">Blog</Button>
                  }
                  {/* <Button className="btn" href="/#testimonials">
                    Testimonial
                  </Button> */}
                </span>
              </Grid>
              <Grid
                item
                xs={4}
                className="logout"
                style={{ alignSelf: "center" }}
              >
                <div style={{ float: "right", display: "inline-flex" }}>
                  <Login />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Header;
