import React, { Component } from 'react'
import fire from '../config/fire'
import { Button, Grid } from '@material-ui/core';
import { InputLabel, NativeSelect } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import Swal from "sweetalert2";
import Drawing from './Drawing'
import moment from 'moment'

class CompanyScore extends Component {

    constructor(props) {
        super(props);

        this.state = {
            quiz: 'All',
            detail: [],
            row: 5
        }
    }

    handleQuiz = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });

        this.mount(event.target.value)
    }

    mount(quiz) {
        if (quiz === 'All') {
            let list = []
            fire.database().ref('batch').once('value', snapshot => {

                snapshot.forEach(snap => {
                    list.push(snap.val());
                })
            })

            fire.database().ref('recro').once('value', snapshot => {

                snapshot.forEach(snap => {
                    list.push(snap.val());
                })
            })

            fire.database().ref('tricog').once('value', snapshot => {

                snapshot.forEach(snap => {
                    list.push(snap.val());
                })

                let ans = list.sort(function (a, b) {

                    return moment(b.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x") - moment(a.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x");
                });

                this.setState({ detail: ans })
            })
        }
        else {
            let list = []
            fire.database().ref(quiz).once('value', snapshot => {

                snapshot.forEach(snap => {
                    list.push(snap.val());
                })

                let ans = list.sort(function (a, b) {

                    return moment(b.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x") - moment(a.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x")
                });

                this.setState({ detail: ans })
            })
        }
    }

    componentDidMount() {
        this.mount(this.state.quiz)
    }

    handleScore = (id, q) => {
        console.log(id, q)

        let val = document.getElementById(id).value
        console.log(val)
        fire.database().ref(q)
            .child(id).update({
                'score': val
            })

        Swal.fire({
            icon: "success",
            title: "Score Updated"
        });

        this.mount(this.state.quiz)
    }

    handleEdit = (id, q) => {
        console.log(id, q)

        fire.database().ref(q)
            .child(id).update({
                'score': ""
            })

        this.mount(this.state.quiz)
    }

    handleNext = () => {
        let dataLength = this.state.row;
        this.setState({ row: dataLength += 5 });
    }

    render() {
        return (
            <div>
                <Grid container><Grid item xs={12}><p></p></Grid></Grid>
                <Grid container className="top">
                    <Grid item xs={6} style={{ padding: '20px' }}>
                        <InputLabel htmlFor="quiz">Company</InputLabel>
                        <NativeSelect id="quiz" name="quiz"
                            value={this.state.quiz} onChange={this.handleQuiz}>
                            <option value="All">All</option>
                            <option value="recro">Recro</option>
                            <option value="tricog">Tricog Health</option>
                            <option value="batch">Batch-I Hiring</option>
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={6} sm={4} md={2} style={{ padding: '20px' }}>
                        <p><b>Total Entries: {this.state.detail.length}</b></p>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} style={{ overflowX: 'auto', marginTop: '20px', padding: '10px' }}>
                        <table className="admin-table">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Attended on</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Quiz</th>
                                    <th>Answers</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.detail.slice(0, this.state.row).map(i => {
                                    return (
                                        <tr key={i.uid}>
                                            <td>{i.date}</td>
                                            <td>{i.name}</td>
                                            <td>{i.email}</td>
                                            <td>{i.quiz}</td>
                                            <td><Drawing quiz={i.quiz} uid={i.uid} scores={i.scores} /></td>
                                            {
                                                i.score === "" ?
                                                    <td>TBD*</td> : <td>{i.score}</td>
                                            }
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        {(this.state.row >= this.state.detail.length) ?
                            (<div className="more" align="center">No More Entries</div>) : (<div className="more" align="center">
                                <Button onClick={this.handleNext} variant="outlined" color="primary" size="large">View more</Button>
                            </div>)
                        }
                    </Grid>
                </Grid>
            </div >
        )
    }
}

export default CompanyScore