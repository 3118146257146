import React, { Component } from 'react'
import fire from '../config/fire'
import { Button, Grid } from '@material-ui/core';
import { InputLabel, NativeSelect } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import Swal from "sweetalert2";
import Drawing from './Drawing'
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment'

class Admin extends Component {

    constructor(props) {
        super(props);

        this.state = {
            quiz: 'All',
            detail: [],
            info: [],
            row: 5
        }
    }

    handleQuiz = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });

        this.mount(event.target.value)
    }

    mount(quiz) {

        if (quiz === 'All') {
            let list = []
            fire.database().ref('Java_stack').once('value', snapshot => {

                snapshot.forEach(snap => {
                    list.push(snap.val());
                })
            })

            fire.database().ref('UI_Developer').once('value', snapshot => {

                snapshot.forEach(snap => {
                    list.push(snap.val());
                })
            })

            fire.database().ref('Devops').once('value', snapshot => {

                snapshot.forEach(snap => {
                    list.push(snap.val());
                })
            })

            fire.database().ref('Competition').once('value', snapshot => {

                snapshot.forEach(snap => {
                    list.push(snap.val());
                })
            })

            fire.database().ref('Sourcing_basic').once('value', snapshot => {

                snapshot.forEach(snap => {
                    list.push(snap.val());
                })
            })

            fire.database().ref('Tech_fundamentals').once('value', snapshot => {

                snapshot.forEach(snap => {
                    list.push(snap.val());
                })
            })

            fire.database().ref('Xray_Sourcing_Advance').once('value', snapshot => {

                snapshot.forEach(snap => {
                    list.push(snap.val());
                })
            })


            fire.database().ref('Xray_Sourcing_Basic').once('value', snapshot => {

                snapshot.forEach(snap => {
                    list.push(snap.val());
                })
            })


            fire.database().ref('Monk_Sourcing_India').once('value', snapshot => {

                snapshot.forEach(snap => {
                    list.push(snap.val());
                })
            })

            fire.database().ref('Boolean_basics').once('value', snapshot => {

                snapshot.forEach(snap => {
                    list.push(snap.val());
                })

                let ans = list.sort(function (a, b) {

                    return moment(b.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x") - moment(a.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x");
                });

                this.setState({ detail: ans })
            })
        }
        else {
            fire.database().ref(quiz).once('value', snapshot => {
                let list = []

                snapshot.forEach(snap => {
                    list.push(snap.val());
                })

                let ans = list.sort(function (a, b) {

                    return moment(b.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x") - moment(a.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x")
                });

                this.setState({ detail: ans })
            })
        }

        fire.database().ref("Details").once('value', snapshot => {
            let i = []

            snapshot.forEach(snap => {
                i.push(snap.val());
            })

            this.setState({ info: i })
        })
    }

    componentDidMount() {
        this.mount(this.state.quiz)
    }

    handleScore = (id, q) => {

        let val = document.getElementById(id).value
        fire.database().ref(q)
            .child(id).update({
                'score': val
            })

        Swal.fire({
            icon: "success",
            title: "Score Updated"
        });

        this.mount(this.state.quiz)
    }

    handleEdit = (id, q) => {

        fire.database().ref(q)
            .child(id).update({
                'score': ""
            })

        this.mount(this.state.quiz)
    }

    handleNext = () => {
        let dataLength = this.state.row;
        this.setState({ row: dataLength += 5 });
    }

    
    render() {
        return (
            <div>
                <Grid container><Grid item xs={12}><p></p></Grid></Grid>
                <Grid container className="top">
                    <Grid item xs={6} style={{ padding: '20px' }}>
                        <InputLabel htmlFor="quiz">Quiz</InputLabel>
                        <NativeSelect id="quiz" name="quiz"
                            value={this.state.quiz} onChange={this.handleQuiz}>
                            <option value="All">All</option>
                            <option value="Boolean_basics">Boolean Basics</option>
                            <option value="Java_stack">Java Stack - I</option>
                            <option value="UI_Developer">UI Developer</option>
                            <option value="Devops">Devops</option>
                            <option value="Sourcing_basic">Sourcing Basic</option>
                            <option value="Tech_fundamentals">Tech Fundamentals</option>
                            <option value="Xray_Sourcing_Basic">Xray Sourcing Basic</option>
                            <option value="Xray_Sourcing_Advance">Xray Sourcing Advance</option>
                            <option value="Monk_Sourcing_India">Monk Sourcing (India)</option>
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={6} sm={4} md={2} style={{ padding: '20px' }}>
                        <p><b>Total Entries: {this.state.detail.length}</b></p>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} style={{ overflowX: 'auto', marginTop: '20px', padding: '10px' }}>
                        <table className="admin-table">
                            <thead className="thead-dark">
                                <tr>
                                    <th>Attended on</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Experience</th>
                                    <th>Linkedin</th>
                                    <th>Contact</th>
                                    <th>Quiz</th>
                                    <th>Answers</th>
                                    {/* <th>Performance</th> */}
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.detail.slice(0, this.state.row).map(i => {
                                    return (
                                        this.state.info.map(data => {
                                            if (data.uid === i.uid)
                                                return (
                                                    <tr key={i.uid}>
                                                        <td>{i.date}</td>
                                                        <td>{i.name}</td>
                                                        <td>{i.email}</td>
                                                        <td>{data.experience}</td>
                                                        <td><Button variant="outlined" href={data.linkedin} target="_blank" color="primary">View</Button></td>
                                                        <td>{data.contact}</td>
                                                        <td>{i.quiz}</td>
                                                        <td><Drawing quiz={i.quiz} uid={i.uid} scores={i.scores} dbperformance={i.performance}/></td>
                                                        {/* <td>{i.performance}</td> */}
                                                        {
                                                            i.score === "" ?
                                                                <td>
                                                                    <Grid container>
                                                                        <Grid item style={{ display: 'inline-flex' }}>
                                                                            <TextField type="number" id={i.uid} name="set"
                                                                                placeholder="Score" variant="outlined" size="small" />
                                                                            <Button onClick={this.handleScore.bind(this, i.uid, i.quiz)} color="primary" variant="outlined">Add</Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </td> : <td>{i.score}</td>
                                                        }
                                                    </tr>
                                                )
                                        })
                                    )
                                })}
                            </tbody>
                        </table>
                        {(this.state.row >= this.state.detail.length) ?
                            (<div className="more" align="center">No More Entries</div>) : (<div className="more" align="center">
                                <Button onClick={this.handleNext} variant="outlined" color="primary" size="large">View more</Button>
                            </div>)
                        }
                    </Grid>
                </Grid>
            </div >
        )
    }
}

export default Admin
