import React from 'react'

function Contact() {
    return(
        <div align="center" style={{ bottom: '0%', padding: '10px', fontSize: '14px' }}>
            <p>Are you facing any technical glitch? <a href="https://wa.me/message/XJKMT6EAGWRHI1">Chat with us</a>.</p>
        </div>
    )
}

export default Contact