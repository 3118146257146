import React, { useEffect, useState } from "react";
import fire from "../../config/fire";
import Swal from "sweetalert2";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Contact from "../Contact";
import { Checkbox } from "@material-ui/core";

function getSteps() {
  return [
    "Question 1",
    "Question 2",
    "Question 3",
    "Question 4",
    "Question 5",
    "Question 6",
    "Question 7",
    "Question 8",
    "Question 9",
    "Question 10",
  ];
}

function Tech_Fundamental_Ques(props) {

  const [enableBeforeUnload, setEnableBeforeUnload] = useState(true);

  const [state, setState] = React.useState({
    name: props.name,
    email: props.email,
    uid: props.uid,
    answer1: [
      { id: 1, value: "NodeJS", isChecked: false },
      { id: 2, value: "ReactJS", isChecked: false },
      { id: 3, value: "AngularJS", isChecked: false },
      { id: 4, value: "VueJs", isChecked: false },
    ],
    answer2: "",
    answer3: [
      { id: 1, value: "(reactnative OR reactredux)", isChecked: false },
      { id: 2, value: "(Phonegap OR Cordova)", isChecked: false },
      { id: 3, value: "(Flutter OR ReactNative)", isChecked: false },
      { id: 4, value: "(angular OR react)", isChecked: false },
    ],
    answer4: [
      { id: 1, value: "HTML", isChecked: false },
      { id: 2, value: "MaterialUI", isChecked: false },
      { id: 3, value: "SASS", isChecked: false },
      { id: 4, value: "SAAS", isChecked: false },
      { id: 5, value: "Less", isChecked: false },
    ],
    answer5: [
      { id: 1, value: "Angular", isChecked: false },
      { id: 2, value: "Backbone", isChecked: false },
      { id: 3, value: "ReactNative", isChecked: false },
      { id: 4, value: "Vue", isChecked: false },
      { id: 5, value: "None of the above", isChecked: false },
    ],
    answer6: [
      { id: 1, value: "GIT", isChecked: false },
      { id: 2, value: "Jenkins", isChecked: false },
      { id: 3, value: "Ant", isChecked: false },
      { id: 4, value: "Maven", isChecked: false },
      { id: 5, value: "None of the above", isChecked: false },
    ],
    answer7: [
      { id: 1, value: "JPA", isChecked: false },
      { id: 2, value: "Eclipse", isChecked: false },
      { id: 3, value: "Hadoop", isChecked: false },
      { id: 4, value: "JDBC", isChecked: false },
      { id: 5, value: "NetBeans", isChecked: false },
    ],
    answer8:[
      { id: 1, value: "Mybatis", isChecked: false },
      { id: 2, value: "Spring", isChecked: false },
      { id: 3, value: "Jboss", isChecked: false },
      { id: 4, value: "Eclipse", isChecked: false },
      { id: 5, value: "None of the above", isChecked: false },
    ],
    answer9: [
      { id: 1, value: "AWS", isChecked: false },
      { id: 2, value: "GCP", isChecked: false },
      { id: 3, value: "Azure", isChecked: false },
      { id: 4, value: "Openstack", isChecked: false },
      { id: 5, value: "Kubernetes", isChecked: false },
    ],
    answer10: [
      { id: 1, value: "(SpringMVC OR Hibernate )", isChecked: false },
      { id: 2, value: "(MongoDB OR Cassandra)", isChecked: false },
      { id: 3, value: "(Jboss OR Jenkins)", isChecked: false },
      { id: 4, value: "(AWS OR Azure)", isChecked: false },
    ],
    date: new Date().toLocaleString("en-IN"),
    score: "",
    performance: "",
    scores: ["", "", "", "", "", "", "", "", "", ""],
    quiz: "Tech_fundamentals",
  });


   useEffect(() => {
      const handleBeforeUnload = (e) => {
          if (enableBeforeUnload) {
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";

        // Add a console message when the user clicks "OK"
        window.addEventListener("unload", () => {
          fire
            .database()
            .ref("Tech_fundamentals")
            .child(props.uid)
            .set(state);
        });
      }
    };

    if (enableBeforeUnload) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [enableBeforeUnload, state]);

  useEffect(() => {
    Swal.fire({
      icon: "warning",
      text: "If you exit the Quiz without completing it, your progress will be Submitted.",
    });
  }, []);


  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleText = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleChange = (event, answerName) => {
    let updatedAnswers = [...state[answerName]]; // Copy the original answers array
    updatedAnswers.forEach((answer) => {
      if (answer.value === event.target.value) {
        answer.isChecked = event.target.checked;
      }
    });
    setState({ ...state, [answerName]: updatedAnswers }); // Update the state with the modified answers array
  };


  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container>
            <Typography>Pick odd one out</Typography>{" "}
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer1.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer1")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid container>
            <Typography>
              What are the top 4 technologies (language, framework etc..) used
              by a UI developer?
              
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <TextField
                label="Enter your answer Here"
                variant="outlined"
                name="answer2"
                onChange={handleText}
                value={state.answer2}
                fullWidth
                multiline
                rowsMax={5}
              />
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container>
            <Typography>Which of the below is wrong?</Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer3.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer3")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );
      case 3:
        return (
          <Grid container>
            <Typography>Pick odd one out</Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer4.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer4")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );
      case 4:
        return (
          <Grid container>
            <Typography>Pick the odd one out from the below?</Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer5.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer5")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );
      case 5:
        return (
          <Grid container>
            <Typography>Pick odd one out (tag : Devops, Backend)</Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer6.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer6")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );
      case 6:
        return (
          <Grid container>
            <Typography>
            Which of the below is not part of Java family
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer7.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer7")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );
      case 7:
        return (
          <Grid container>
            <Typography>
              Which of the below is not part of Java based technology?
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer8.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer8")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );
      case 8:
        return (
          <Grid container>
            <Typography>
              Which of the below is not a cloud technology?
            </Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer9.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer9")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );
      case 9:
        return (
          <Grid container>
            <Typography>Which of the below is wrong</Typography>
            <Grid item xs={12} style={{ padding: "10px" }}>
              <FormControl component="fieldset">
                {state.answer10.map((data) => {
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            key={data.id}
                            onChange={(event) => handleChange(event, "answer10")}
                            checked={data.isChecked}
                            value={data.value}
                          />
                        }
                        label={data.value}
                      />
                    </div>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        );
    }
  };

  const handleSubmit = () => {
    setEnableBeforeUnload(false)
    if (props.fill === true) {
      fire.database().ref("Tech_fundamentals").child(props.uid).set(state);

      Swal.fire({
        icon: "success",
        title: "Awesome! You've completed Tech Fundamentals Quiz",
        text: "You'll receive score within 24HRs",
      }).then(function () {
        window.location.href = "/share-experience";
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "OOPS",
        text: "Quiz already attempted",
      }).then(function () {
        window.location.href = "/";
      });
    }
  };

  if (props.attempt)
    return (
      <div>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(index)}</Typography>
                <div>
                  <div>
                    <Button disabled={activeStep === 0} onClick={handleBack}>
                      Back
                    </Button>
                    {activeStep === steps.length - 1 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                      >
                        Finish
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        Next/Skip
                      </Button>
                    )}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>

        <Contact />
      </div>
    );
  else handleSubmit();
}

export default Tech_Fundamental_Ques;
