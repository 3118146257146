import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


export default class T2 extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Card className="Tmon">
                <CardContent className="media" align="center">
                    <img src="/images/Piyush.jpeg" alt="Piyush" />
                </CardContent>
                <CardContent align="center">
                    Recruting Monk- It is the centre for all talent acquisition professionals, one must attend this series in their career span. The insights and experiences shared are way high and provides the real time scenarios. Must have for the TA professionals!!
                </CardContent>
                <div className="names" align="center">
                    <h3>Piyush Naik, Ph.D</h3>
                    <p>DigiValet Inc.</p>
                </div>
            </Card>
        );
    }
}