import React, { Component } from "react";
import { Dialog, DialogContent, Button, Grid } from "@material-ui/core";
import { signInWithGoogle, signInWithFb } from "./config/fire";
import FacebookIcon from "@material-ui/icons/Facebook";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  componentDidMount() {}

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <div align="center">
        <Button
          className="button-default"
          variant="contained"
          onClick={this.handleOpen}
        >
          Login
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="login-popup"
          fullWidth
        >
          <DialogContent>
            <Grid container className="login">
              <Grid item xs={12}>
                <p className="head">
                  Login & test your <b>IT & sourcing skills</b>
                </p>
              </Grid>
              <Grid item xs={12}>
                <p></p>
              </Grid>
              <Grid item xs={12}>
                <Button
                  class="googleBtn"
                  onClick={signInWithGoogle}
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    preserveAspectRatio="xMidYMid"
                    viewBox="0 0 256 262"
                    id="google"
                    style={{paddingRight: "5px"}}
                  >
                    <path
                      fill="#fff"
                      d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                    ></path>
                    <path
                      fill="#fff"
                      d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                    ></path>
                    <path
                      fill="#fff"
                      d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                    ></path>
                    <path
                      fill="#fff"
                      d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                    ></path>
                  </svg>
                  Login With Google
                </Button>
              </Grid>
              <Grid item xs={12}>
                <p></p>
              </Grid>
              <Grid item xs={12}>
                <Button
                  class="fbBtn"
                  onClick={signInWithFb}
                  style={{ cursor: "pointer" }}
                >
                  <FacebookIcon /> Login With Facebook
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default Login;
