import React, { Component } from 'react'
import fire from '../config/fire'
import { Button, Grid } from '@material-ui/core';
import { InputLabel, NativeSelect } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import Swal from "sweetalert2";
import Drawing from './Drawing'
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment'

class Unscore extends Component {

    constructor(props) {
        super(props);

        this.state = {
            detail: []
        }
    }

    componentDidMount() {
        let list = []
        fire.database().ref('Java_stack').once('value', snapshot => {

            snapshot.forEach(snap => {
                if (snap.val().score === "")
                    list.push(snap.val());
            })
        })

        fire.database().ref('UI_Developer').once('value', snapshot => {

            snapshot.forEach(snap => {
                if (snap.val().score === "")
                    list.push(snap.val());
            })
        })

        fire.database().ref('Devops').once('value', snapshot => {

            snapshot.forEach(snap => {
                if (snap.val().score === "")
                    list.push(snap.val());
            })
        })

        fire.database().ref('Boolean_basics').once('value', snapshot => {

            snapshot.forEach(snap => {
                if (snap.val().score === "")
                    list.push(snap.val());
            })

            let ans = list.sort(function (a, b) {

                return moment(b.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x") - moment(a.date, "DD/MM/YYYY hh:mm:ss am/pm").format("x");
            });

            this.setState({ detail: ans })
        })
    }

    handleScore = (id, q) => {
        console.log(id, q)

        let val = document.getElementById(id).value
        console.log(val)
        fire.database().ref(q)
            .child(id).update({
                'score': val
            })

        Swal.fire({
            icon: "success",
            title: "Score Updated"
        });

        this.componentDidMount()
    }

    render() {
        return (
            <div>
                <Grid container><Grid item xs={12}><p></p></Grid></Grid>
                <Grid container className="top">
                    <Grid item xs={6} sm={4} md={2} style={{ padding: '20px' }}>
                        <p><b>Total Entries: {this.state.detail.length}</b></p>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} style={{ overflowX: 'auto', marginTop: '20px', padding: '10px' }}>
                        <table className="admin-table">
                            <thead class="thead-dark">
                                <tr>
                                    <th>Attended on</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Quiz</th>
                                    <th>Answers</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.detail.map(i => {
                                    return (
                                        <tr key={i.uid}>
                                            <td>{i.date}</td>
                                            <td>{i.name}</td>
                                            <td>{i.email}</td>
                                            <td>{i.quiz}</td>
                                            <td><Drawing quiz={i.quiz} uid={i.uid} scores={i.scores} /></td>
                                            <td>
                                                <Grid container>
                                                    <Grid item style={{ display: 'inline-flex' }}>
                                                        <TextField type="number" id={i.uid} name="set"
                                                            placeholder="Score" variant="outlined" size="small" />
                                                        <Button onClick={this.handleScore.bind(this, i.uid, i.quiz)} color="primary" variant="outlined">Add</Button>
                                                    </Grid>
                                                </Grid>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
            </div >
        )
    }
}

export default Unscore