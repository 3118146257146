import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


export default class T3 extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Card className="Tmon">
                <CardContent className="media" align="center">
                    <img src="/images/Varun.jpeg" alt="Varun" />
                </CardContent>
                <CardContent align="center">
                    The one and only platform for tech recruiters to test their metal. I strongly recommend every Recruiter to take up this challenge!
                </CardContent>
                <div className="names" align="center">
                    <h3>Varun Kapadia</h3>
                    <p>PayPal</p>
                </div>
            </Card>
        );
    }
}