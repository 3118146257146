import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Loader from '../Loader'
import HomeIcon from '@material-ui/icons/Home';
import Avatar from '@material-ui/core/Avatar';
import { auth } from "../config/fire";
import fire from '../config/fire'
import Graphing from './Graphing'
import AdminView from './AdminView'

function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`admin-tabpanel-${index}`}
            aria-labelledby={`admin-tab-${index}`}
            className="admin-tabpanel"
        >
            {value === index && (
                <div>{children}</div>
            )}
        </div>
    );
}

class CompanyDash extends Component {

    constructor(props) {
        super(props)

        this.state = {
            value: 0,
            fill: false,
            quiz: '',
            company: ''
        }
    }

    componentDidMount() {
        let q, c
        fire
            .database()
            .ref("CompanyDash")
            .once('value', snapshot => {
                if (snapshot.hasChild(this.props.uid))
                    this.setState({
                        fill: true
                    })

                snapshot.forEach(snap => {
                    if (this.props.uid === snap.val().uid) {
                        q = snap.val().quiz;
                        c = snap.val().company;
                    }
                })

                this.setState({ quiz: q, company: c })
            });
    }

    handleChange = (event, newValue) => {
        this.setState({
            value: newValue
        })
    };

    render() {
        if (this.state.fill && this.state.quiz)
            return (
                <div>
                    <Loader />
                    <Grid container style={{ display: 'inline-flex', backgroundColor: 'cornflowerblue' }}>
                        <Grid item xs={6} style={{ alignSelf: 'center', display: 'inline-flex' }}>
                            <h3 className="name" style={{ float: 'left', color: 'white' }}>Welcome {this.state.company}</h3>
                        </Grid>
                        <Grid item xs={6} className="logout" style={{ alignSelf: 'center' }}>
                            <div style={{ float: 'right', display: 'inline-flex' }}>
                                <Avatar style={{ marginRight: '10px' }} alt={this.props.name} src={this.props.photoURL || "https://res.cloudinary.com/dqcsk8rsc/image/upload/v1577268053/avatar-1-bitmoji_upgwhc.png"} />
                                <Button onClick={() => { auth.signOut() }} size="small" variant="contained" color="primary">Logout</Button>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <div style={{ padding: '15px', marginBottom: '30px' }}>
                                <Button
                                    style={{ float: 'right' }}
                                    variant="contained"
                                    color="primary"
                                    href="/"
                                    startIcon={<HomeIcon />}
                                >
                                    Go to Home</Button>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} style={{ padding: '20px' }}>
                            <Tabs value={this.state.value} onChange={this.handleChange} aria-label="admin-tabs" className="admin-tabs" >
                                <Tab label="Quiz Taken" />
                                <Tab label="Visualization" />
                            </Tabs>

                            <TabPanel value={this.state.value} index={0}>
                                <AdminView quiz={this.state.quiz} />
                            </TabPanel>
                            <TabPanel value={this.state.value} index={1}>
                                <Graphing quiz={this.state.quiz} />
                            </TabPanel>
                        </Grid>
                    </Grid>
                </div>
            )
        else
            return (
                <div>
                    <Loader />
                You don't have access to this page!
                </div >
            )
    }
}

export default CompanyDash;
