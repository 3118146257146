import React from 'react'
import fire from '../../config/fire'
import Swal from "sweetalert2";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from "@material-ui/core/TextField";
import Typography from '@material-ui/core/Typography';
import Contact from '../Contact'

function getSteps() {
    return ['Question 1', 'Question 2', 'Question 3', 'Question 4', 'Question 5'];
}

function DevopsQues(props) {

    const [state, setState] = React.useState({
        name: props.name,
        email: props.email,
        uid: props.uid,
        answer1: [
            { id: 1, value: '(GCP OR Azure)', isChecked: false },
            { id: 2, value: '(JavaScript OR Perl)', isChecked: false },
            { id: 3, value: '(Cloud OR Microservices)', isChecked: false },
            { id: 4, value: '(Webservices OR Microservices)', isChecked: false }
        ],
        answer2: "",
        answer3: "",
        answer4: "",
        answer5: "",
        date: new Date().toLocaleString("en-IN"),
        score: "",
        scores: ["", "", "", "", ""],
        quiz: "Devops"
    })

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleText = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };

    const handleChange = (event) => {
        let fruites = state.answer1
        fruites.forEach(fruite => {
            if (fruite.value === event.target.value)
                fruite.isChecked = event.target.checked
        })
        setState({ ...state, answer1: fruites })
    }

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <Grid container>
                        <Typography>Which of the below is wrong?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                {
                                    state.answer1.map(data => {
                                        return (
                                            <div>
                                                <FormControlLabel control={<Checkbox key={data.id} onChange={handleChange} checked={data.isChecked} value={data.value} />} label={data.value} />
                                            </div>
                                        )
                                    })
                                }
                            </FormControl>
                        </Grid>
                    </Grid>
                )
            case 1:
                return (
                    <Grid container>
                        <Typography>Write a Boolean search for the below Devops Role. Ensure you write one search, that will give quantitative & qualitative data sets.</Typography>
                        <ul>
                            <li>Hands on experience as Devops Engineer.</li>
                            <li>Experience in working with scalable applications.</li>
                            <li>Should have good hands on with scripting languages like Python, JavaScript.</li>
                            <li>Experience of working in microservices environment.</li>
                            <li>Exposure to any of the cloud platforms.</li>
                            <li>Should have hands on experience with CI/CD technologies.</li>
                        </ul>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <TextField label="Enter your answer Here" variant="outlined" name="answer2"
                                onChange={handleText} value={state.answer2} fullWidth multiline rowsMax={5} />
                        </Grid>
                    </Grid>
                )
            case 2:
                return (
                    <Grid container>
                        <Typography>List out some of the CI/CD technologies</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <TextField label="Enter your answer Here" variant="outlined" name="answer3"
                                onChange={handleText} value={state.answer3} fullWidth multiline rowsMax={5} />
                        </Grid>
                    </Grid>
                )
            case 3:
                return (
                    <Grid container>
                        <Typography>What will be your Boolean for the below need:</Typography>
                        <p>Experience with any two configuration management tools is mandatory : Chef, Puppet, Salt, Ansible.</p>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <TextField label="Enter your answer Here" variant="outlined" name="answer4"
                                onChange={handleText} value={state.answer4} fullWidth multiline rowsMax={5} />
                        </Grid>
                    </Grid>
                )
            case 4:
                return (
                    <Grid container>
                        <Typography>Which of the below cannot be a part of Devops stack ?</Typography>
                        <Grid item xs={12} style={{ padding: '10px' }}>
                            <FormControl component="fieldset">
                                <RadioGroup name="answer5" value={state.answer5} onChange={handleText}>
                                    <FormControlLabel value="Linux" control={<Radio />} label="Linux" />
                                    <FormControlLabel value="Chef" control={<Radio />} label="Chef" />
                                    <FormControlLabel value="SVN" control={<Radio />} label="SVN" />
                                    <FormControlLabel value="Networking" control={<Radio />} label="Networking" />
                                    <FormControlLabel value="None of the above" control={<Radio />} label="None of the above" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                )
        }
    }

    const handleSubmit = () => {

        if (props.fill === true) {
            fire
                .database()
                .ref("Devops")
                .child(props.uid)
                .set(state)

            Swal.fire({
                icon: "success",
                title: "Awesome! You've completed Devops Test",
                text: "You'll receive score within 24HRs"
            }).then(function () {
                window.location.href = '/share-experience'
            });
        }
        else {
            Swal.fire({
                icon: "error",
                title: "OOPS",
                text: "Quiz already attempted"
            }).then(function () {
                window.location.href = '/'
            });
        }
    };

    if (props.attempt)
        return (
            <div>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                                <Typography>{getStepContent(index)}</Typography>
                                <div>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                        >Back</Button>
                                        {activeStep === steps.length - 1 ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit}
                                            >Finish</Button>
                                            :
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleNext}
                                            >Next/Skip</Button>
                                        }
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                <Contact />
            </div>
        )
    else
        handleSubmit()
}

export default DevopsQues