import React from 'react'
import { Button, Grid } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

export default function Sidebar() {
    return (
        <Grid container className="sidebar">
            <Grid item xs={12}>
                <Card style={{ padding: '10px 0 30px 0' }}>
                    <CardContent>
                        <h2>Assessment Platform</h2>
                        <p>Want to assess tech recruiters & save your hiring efforts by 30%?</p>
                        <Button variant="contained" className="button-default" target="_blank" href="https://wa.me/message/XJKMT6EAGWRHI1" >Talk To Us</Button>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <h2><EventIcon style={{ padding: '3px', paddingRight: '10px' }} /> Upcoming events</h2>
                <Card className="side-card" onClick={() => window.open('https://recruitingmonk.com/sourcing-workshop/', '_blank')}>
                    <CardContent>
                        <h4>Weekend Sourcing Sessions</h4>
                        <p>Learn bigdata & probabilistic concepts in sourcing & basic tech know how's.</p>
                    </CardContent>
                </Card>
                <br />
                <Card className="side-card" onClick={() => window.open('https://wa.me/message/XJKMT6EAGWRHI1', '_blank')}>
                    <CardContent>
                        <h4>Mini Diploma for TA</h4>
                        <p>100 HRs coaching series for experienced TA professionals.</p>
                    </CardContent>
                </Card>
                <br />
                <Card className="side-card" onClick={() => window.open('https://wa.me/message/XJKMT6EAGWRHI1', '_blank')}>
                    <CardContent>
                        <h4>Sourcing Master Class</h4>
                        <p>Learn bigdata & probabilistic concepts in sourcing & basic tech know how's.</p>
                    </CardContent>
                </Card>

            </Grid>
        </Grid>
    )
}