import React, { Component } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle,
  TextField,
  Grid,
} from "@material-ui/core";
import Swal from "sweetalert2";
import fire from "../config/fire";

class InfoGather extends Component {
  constructor(props) {
    super(props);

    this.state = {
      linkedin: "",
      experience: "",
      contact: "",
      description: "",
      uid: props.uid,
      open: false,
      photoURL: props.photoURL,
      name: "",
      error: "",
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  componentDidMount() {
    const getData = async () => {
      const snapshot = await fire
        .database()
        .ref("Details")
        .child(this.props.uid)
        .once("value");

      if (snapshot.exists()) {

        const data = snapshot.val();
        
        // field are present means new user
        if (data.name) {
          this.setState({
            open: false,
          });

        //   name field not there means old user update the fields
        } else {
          this.setState({
            open: true,
          });
        }

        // user details not exists just signed in for the app
      } else {
        this.setState({
          open: true,
        });
      }
    };

    getData();
  }

  handleOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  validateLinkedin(link) {
    // Check if the link matches a LinkedIn URL pattern
    if (/linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.test(link)) {
      // Check if the link starts with "http://" or "https://"
      if (!/^(https?:\/\/)/i.test(link)) {
        // If it doesn't have a protocol, add "https://"
        const updatedLink = "https://" + link;
        this.setState({
          linkedin: updatedLink,
        });
      } else {
        // If it already has a protocol, use the link as is
        this.setState({
          linkedin: link,
        });
      }
      
      return true; // Valid LinkedIn profile link
    }
  
    return false; // Invalid LinkedIn profile link
  }  
  
  handleSubmit = (e) => {
    e.preventDefault();
    
    if (this.validateLinkedin(this.state.linkedin)) {
      this.handleClose();

      fire.database().ref("Details").child(this.props.uid).set(this.state);

      Swal.fire({
        icon: "success",
        title: "Thank You!",
      });
    } else {
      this.setState({
        error: "Try again",
      });
    }
  };

  render() {
    return (
      <div align="center">
        <Dialog
          open={this.state.open}
          aria-labelledby="information-gathering-popup"
          fullWidth
        >
          <DialogTitle>Complete Profile</DialogTitle>
          <DialogContent>
            <DialogContentText>Tell Us A Bit About Yourself</DialogContentText>
            <form onSubmit={this.handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    id="linkedin"
                    onChange={this.handleChange}
                    label="Linkedin Profile"
                    name="linkedin"
                    value={this.state.linkedin}
                    fullWidth
                    required
                    helperText={this.state.error}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    id="experience"
                    name="experience"
                    required={true}
                    value={this.state.experience}
                    onChange={this.handleChange}
                    InputProps={{ inputProps: { min: 0, max: 50 } }}
                    label="Experience (in years)"
                    fullWidth
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    name="name"
                    required={true}
                    value={this.state.name}
                    onChange={this.handleChange}
                    InputProps={{ inputProps: { min: 0, max: 50 } }}
                    label="Full Name"
                    fullWidth
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="description"
                    name="description"
                    required={true}
                    value={this.state.description}
                    onChange={this.handleChange}
                    InputProps={{ inputProps: { min: 0, max: 50 } }}
                    label="Description (Your Bio)"
                    fullWidth
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="tel"
                    id="contact"
                    name="contact"
                    required={true}
                    value={this.state.contact}
                    onChange={this.handleChange}
                    InputProps={{
                      inputProps: {
                        minlength: 10,
                        maxlength: 10,
                        pattern: "^[0-9]+$",
                      },
                    }}
                    title="Mobile number should consist of number only"
                    label="Mobile Number"
                    fullWidth
                  ></TextField>
                </Grid>
              </Grid>
              <br />
              <br />
              <DialogActions>
                <Button type="submit" color="primary" variant="outlined">
                  Submit
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default InfoGather;
